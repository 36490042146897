import ContentTooltip from '../shared/ContentTooltip'
import MeterContent from '../shared/MeterContent'
import TextContent from '../shared/TextContent'
import SensorContent from './SensorContent'
import MountainContent from './MountainContent'
import { mountain } from './mountainObject'
import InfoboxContent from '../shared/InfoboxContent'

export const libraryObjects = {
  text: {
    img: '/static/images/text.svg',
    name: 'Testo',
    notes: 'Oggetto di testo per scrivere liberamente',
    draw: {
      type: 'text',
      text: 'Testo',
      backgroundColor: '#fff',
      left: 0,
      top: 0
    },
    modal: (props) => <TextContent {...props} />,
    onReceive: (thisDraw, config) => {
      if (config.data && config.data.currentText) {
        thisDraw.text = config.data.currentText
      }
      return thisDraw
    },
    event: {
      onLoad: (thisDraw, config) => {
        if (config.data && config.data.currentText) {
          thisDraw.text = config.data.currentText
        }
        return thisDraw
      },
      mouseDown: () => { },
      mouseOver: () => { }
    }
  },
  sensor: {
    img: '/static/images/sensor.svg',
    name: 'Sensore di livello',
    notes: 'Sensore di livello',
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: []
    },
    modal: (props) => <SensorContent {...props} />,
    tooltip: (props) => <ContentTooltip {...props} />,
    onReceive: (thisDraw, config) => {
      thisDraw.objects = []
      for (let s = 0; s < config.assets.length; s++) {
        let index = 2
        const background = {
          type: 'rect',
          width: 120,
          height: config.additionalData?.selectedProperties.length * 10 + 14,
          fill: 'white',
          stroke: 'white',
          top: -5,
          left: 10,
          originX: 'left',
          originY: 'top'
        }
        thisDraw.objects.push(background)
        const name = {
          type: 'text',
          text: String(config.data?.draw?.name),
          left: 10,
          top: 0,
          originX: 'left',
          originY: 'center',
          fontSize: 10,
          textAlign: 'left'
        }
        thisDraw.objects.push(name)
        for (const n in config.additionalData.properties) {
          if (config.additionalData?.selectedProperties.includes(config.additionalData.properties[n].propKey)) {
            const label = {
              type: 'text',
              text: String(config.additionalData.properties[n].label),
              left: 10,
              top: index === 2 ? 14 : (index - 1) * 12,
              originX: 'left',
              originY: 'center',
              fontSize: 8,
              textAlign: 'left'
            }
            thisDraw.objects.push(label)
            const value = {
              type: 'text',
              text: String(config.additionalData.properties[n].value),
              left: 120,
              top: index === 2 ? 14 : (index - 1) * 12,
              originX: 'right',
              originY: 'center',
              fontSize: 8,
              textAlign: 'right'
            }
            thisDraw.objects.push(value)
            index++
          }
        }
      }

      return thisDraw
    },
    event: {
      onLoad: (thisDraw, config) => {
        thisDraw.objects = []
        for (let s = 0; s < config.assets.length; s++) {
          let index = 2
          const background = {
            type: 'rect',
            width: 120,
            height: config.additionalData?.selectedProperties.length * 10 + 14,
            fill: 'white',
            stroke: 'white',
            top: -5,
            left: 10,
            originX: 'left',
            originY: 'top'
          }
          thisDraw.objects.push(background)
          const name = {
            type: 'text',
            text: String(config.data?.draw?.name),
            left: 10,
            top: 0,
            originX: 'left',
            originY: 'center',
            fontSize: 10,
            textAlign: 'left'
          }
          thisDraw.objects.push(name)
          for (const n in config.assets[s].properties) {
            if (config.additionalData?.selectedProperties.includes(config.assets[s].properties[n].propKey)) {
              const label = {
                type: 'text',
                text: String(config.assets[s].properties[n].label),
                left: 10,
                top: index === 2 ? 14 : (index - 1) * 12,
                originX: 'left',
                originY: 'center',
                fontSize: 8,
                textAlign: 'left'
              }
              thisDraw.objects.push(label)
              const value = {
                type: 'text',
                text: String(config.assets[s].properties[n].value),
                left: 120,
                top: index === 2 ? 14 : (index - 1) * 12,
                originX: 'right',
                originY: 'center',
                fontSize: 8,
                textAlign: 'right'
              }
              thisDraw.objects.push(value)
              index++
            }
          }
        }

        return thisDraw
      },
      mouseDown: () => { },
      mouseOver: () => {}
    }
  },
  energymeter: {
    img: '/static/images/energy.svg',
    name: 'Meter',
    notes: "Meter dell' impianto",
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: [
        {
          type: 'circle',
          radius: 30,
          fill: '#fff',
          stroke: '#000',
          originX: 'center',
          originY: 'center'
        },
        {
          type: 'text',
          text: 'M',
          left: 0,
          top: 0,
          originX: 'center',
          originY: 'center'
        }
      ]
    },
    modal: (props) => <MeterContent {...props} />,
    tooltip: (props) => <ContentTooltip {...props} />,
    onReceive: (canvasObj, config) => {
      // 1. se non esiste ritorno null
      // console.log('meter - config: ', config)
      // console.log('meter - canvasObj: ', canvasObj)

      const newObj = canvasObj
      let generalStatus = ''

      for (let s = 0; s < config.devices.length; s++) {
        if (
          config.devices[s].properties &&
          config.devices[s].properties.status &&
          config.devices[s].properties.status.value &&
          config.devices[s].properties.status.value.code
        ) {
          generalStatus = config.devices[s].properties.status.value.code
        }
      }
      if (newObj.objects && newObj.objects.length > 0) {
        if (generalStatus === 'ok') {
          // newObj.objects[0].fill = 'white'
          newObj.objects[0].fill = (config.data && config.data.draw && config.data.draw.backgroundColor) || 'white'
        } else if (generalStatus === 'warning' || generalStatus === 'maintenance') {
          newObj.objects[0].fill = 'yellow'
        } else if (generalStatus === 'error') {
          newObj.objects[0].fill = 'red'
        }
      }

      return newObj
    },
    event: {
      mouseDown: () => {
        console.log('onMouseDown')
      },
      mouseOver: (config) => {
        const infos = []
        console.log('onMouseOver: ', config)
        for (let s = 0; s < config.devices.length; s++) {
          for (const n in config.devices[s].properties) {
            if (n !== 'status') {
              const label = String(config.devices[s].properties[n].label)
              const value = String(config.devices[s].properties[n].value)
              infos.push({ label, value })
            }
          }
        }

        return infos
      }
    }
  },
  infobox: {
    img: '/static/images/text.svg',
    name: 'Infobox',
    notes: "Oggetto di testo per rappresentare valori sugli altri elementi dell'impianto",
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: []
    },
    modal: (props) => <InfoboxContent {...props} />,
    // onReceive: (canvasObj, currentValues) => (objModified),
    onReceive: (thisDraw, config) => {
      const backgroundColor = config.data && config.data.draw && config.data.draw.backgroundColor
      thisDraw.objects = []
      // console.log('infobox - onReceive: ', thisDraw)
      // console.log('infobox - onReceive config: ', config)
      for (let s = 0; s < config.devices.length; s++) {
        let index = 2
        const selectedProperties = config.additionalData?.selectedProperties ?? []

        const filteredProperties = Object.keys(config.devices[s].properties)
          .filter(el => selectedProperties.includes(el))
          .reduce((acc, propKey) => {
            if (!acc[propKey]) {
              acc[propKey] = config.devices[s].properties[propKey]
            }

            return acc
          }, {})

        const background = {
          type: 'rect',
          width: 150,
          height: Object.keys(filteredProperties).filter(el => el !== 'status').length * 12 + 20,
          fill: backgroundColor || 'white',
          stroke: backgroundColor || 'white',
          top: -5,
          left: 10,
          originX: 'left',
          originY: 'top'
        }
        thisDraw.objects.push(background)
        const name = {
          type: 'text',
          text: String(config.data?.draw?.name),
          left: 10,
          top: 0,
          originX: 'left',
          originY: 'center',
          fontSize: 10,
          textAlign: 'left'
        }
        if (config.data?.draw?.name) {
          thisDraw.objects.push(name)
        }
        for (const n in filteredProperties) {
          if (n !== 'status') {
            const label = {
              type: 'text',
              text: String(filteredProperties[n].label),
              left: 10,
              top: index === 2 ? 14 : (index - 1) * 12,
              originX: 'left',
              originY: 'center',
              fontSize: 8,
              textAlign: 'left'
            }
            thisDraw.objects.push(label)
            const value = {
              type: 'text',
              text: String(filteredProperties[n].value),
              left: 150,
              top: index === 2 ? 14 : (index - 1) * 12,
              originX: 'right',
              originY: 'center',
              fontSize: 8,
              textAlign: 'right'
            }
            thisDraw.objects.push(value)
            index++
          }
        }
      }
      return thisDraw
    },
    event: {
      onLoad: (thisDraw, config) => {
        thisDraw.objects = []
        // console.log('onLoad: ', thisDraw)
        // console.log('config: ', config)
        for (let s = 0; s < config.devices.length; s++) {
          let index = 2
          const selectedProperties = config.additionalData?.selectedProperties ?? []

          const filteredProperties = Object.keys(config.devices[s].properties)
            .filter(el => selectedProperties.includes(el))
            .reduce((acc, propKey) => {
              if (!acc[propKey]) {
                acc[propKey] = config.devices[s].properties[propKey]
              }

              return acc
            }, {})

          const background = {
            type: 'rect',
            width: 150,
            height: Object.keys(filteredProperties).filter(el => el !== 'status').length * 10 + 14,
            fill: 'white',
            stroke: 'white',
            top: -5,
            left: 10,
            originX: 'left',
            originY: 'top'
          }
          thisDraw.objects.push(background)
          const name = {
            type: 'text',
            text: String(config.data?.draw?.name),
            left: 10,
            top: 0,
            originX: 'left',
            originY: 'center',
            fontSize: 10,
            textAlign: 'left'
          }
          if (config.data?.draw?.name) {
            thisDraw.objects.push(name)
          }
          for (const n in filteredProperties) {
            if (n !== 'status') {
              const label = {
                type: 'text',
                text: String(filteredProperties[n]?.label),
                left: 10,
                top: index === 2 ? 14 : (index - 1) * 12,
                originX: 'left',
                originY: 'center',
                fontSize: 8,
                textAlign: 'left'
              }
              thisDraw.objects.push(label)
              const value = {
                type: 'text',
                text: String(filteredProperties[n]?.value),
                left: 120,
                top: index === 2 ? 14 : (index - 1) * 12,
                originX: 'right',
                originY: 'center',
                fontSize: 8,
                textAlign: 'right'
              }
              thisDraw.objects.push(value)
              index++
            }
          }
        }

        return thisDraw
      },
      mouseDown: () => {
        console.log('onMouseDown')
      },
      mouseOver: () => {
        console.log('onMouseOver')
      }
    }
  },
  mountain: {
    img: '/static/images/text.svg',
    name: 'Montagna',
    notes: 'Sfondo idroelettrico',
    menuHide: true,
    draw: {
      type: 'group',
      selectable: false,
      left: 0,
      top: 0,
      objects: mountain
      /* [
        {
          type: 'path',
          fill: '#636631',
          path: 'M0 490.5V0L31.5 47.5H67.5L129.5 110.5H149.5L185 71.5L250 47.5L367.5 71.5L399 123L431.5 154.5L453 224L930.5 234L1115 426.5L1156 375L1210.5 367.5V490.5H0Z'
        },
        {
          type: 'path',
          path: 'M403.654 41.4199L417.649 1.96021H429.144L443.138 41.4199M403.654 41.4199L366.17 75.3853M403.654 41.4199L443.138 75.3853M403.654 41.4199V75.3853M403.654 41.4199H443.138M366.17 75.3853V90.37M366.17 75.3853H480.622M403.654 121.338L378.665 199.758H385.162L423.146 147.811M403.654 121.338L423.146 147.811M403.654 121.338V113.846M423.146 147.811L461.63 199.758H468.128L443.138 121.338M423.146 147.811L443.138 121.338M403.654 113.846L443.138 75.3853M403.654 113.846V75.3853M443.138 75.3853V41.4199M443.138 75.3853V113.846M443.138 75.3853L492.618 113.846V130.829M403.654 75.3853L354.175 113.846V130.829M403.654 75.3853L443.138 113.846M403.654 75.3853L443.138 41.4199M443.138 41.4199L480.622 75.3853M480.622 75.3853V90.37M443.138 121.338V113.846',
          stroke: '#CDCDCD',
          fill: 'transparent',
          strokeWidth: 2,
          strokeLinecap: 'round',
          strokeLinejoin: 'bevel',
          top: 39,
          left: 787
        },
        {
          type: 'path',
          path: 'M263.712 161.298V93.3669H769.503M284.703 161.298V114.845H769.503M306.195 161.298V133.326H769.503',
          stroke: 'black',
          fill: 'transparent',
          strokeWidth: 2,
          strokeLinejoin: 'round',
          top: 130,
          left: 697
        },
        {
          type: 'path',
          path: 'M108.776 152.806H242.721V217.74H108.776V152.806Z',
          fill: '#FDFFCD',
          top: 190,
          left: 540
        },
        {
          type: 'path',
          path: 'M124.769 165.793H133.766V199.758H124.769V165.793Z',
          fill: '#29FFFE',
          top: 203,
          left: 554
        },
        {
          type: 'path',
          path: 'M144.761 165.793H153.758V199.758H144.761V165.793Z',
          fill: '#29FFFE',
          top: 203,
          left: 574
        },
        {
          type: 'path',
          path: 'M162.754 165.793H171.75V199.758H162.754V165.793Z',
          fill: '#29FFFE',
          top: 203,
          left: 594
        },
        {
          type: 'path',
          path: 'M181.746 165.793H190.742V199.758H181.746V165.793Z',
          fill: '#29FFFE',
          top: 203,
          left: 614
        },
        {
          type: 'path',
          path: 'M200.738 165.793H209.734V199.758H200.738V165.793Z',
          fill: '#29FFFE',
          top: 203,
          left: 634
        },
        {
          type: 'path',
          path: 'M219.73 165.793H228.727V199.758H219.73V165.793Z',
          fill: '#29FFFE',
          top: 203,
          left: 654
        },
        {
          type: 'path',
          path: 'M0.320923 107.852L2.81989 76.8838H10.3168L7.81782 107.852L79.7881 326.129H93.2825V217.241H256.715V189.769H317.69V217.241H480.622V365.589H93.2825V337.118H75.7897L0.320923 107.852Z',
          fill: '#D9D9D9',
          top: 114,
          left: 433
        },
        {
          type: 'path',
          path: 'M259.714 162.297C259.714 159.814 261.728 157.801 264.212 157.801C266.696 157.801 268.71 159.814 268.71 162.297V190.768H259.714V162.297Z',
          fill: '#D9D9D9',
          top: 198,
          left: 693
        },
        {
          type: 'path',
          path: 'M280.705 162.297C280.705 159.814 282.719 157.801 285.203 157.801C287.687 157.801 289.701 159.814 289.701 162.297V190.768H280.705V162.297Z',
          fill: '#D9D9D9',
          top: 198,
          left: 714
        },
        {
          type: 'path',
          path: 'M301.696 162.297C301.696 159.814 303.71 157.801 306.195 157.801C308.679 157.801 310.693 159.814 310.693 162.297V190.768H301.696V162.297Z',
          fill: '#D9D9D9',
          top: 198,
          left: 735
        },
        {
          type: 'path',
          path: 'M258.714 187.771H269.71V188.77H258.714V187.771Z',
          fill: '#BFBEBE',
          top: 225,
          left: 692
        },
        {
          type: 'path',
          path: 'M258.714 184.774H269.71V185.773H258.714V184.774Z',
          fill: '#BFBEBE',
          top: 222,
          left: 692
        },
        {
          type: 'path',
          path: 'M258.714 181.777H269.71V182.776H258.714V181.777Z',
          fill: '#BFBEBE',
          top: 219,
          left: 692
        },
        {
          type: 'path',
          path: 'M258.714 178.78H269.71V179.779H258.714V178.78Z',
          fill: '#BFBEBE',
          top: 216,
          left: 692
        },
        {
          type: 'path',
          path: 'M258.714 175.783H269.71V176.782H258.714V175.783Z',
          fill: '#BFBEBE',
          top: 213,
          left: 692
        },
        {
          type: 'path',
          path: 'M258.714 172.786H269.71V173.785H258.714V172.786Z',
          fill: '#BFBEBE',
          top: 210,
          left: 692
        },
        {
          type: 'path',
          path: 'M258.714 169.789H269.71V170.788H258.714V169.789Z',
          fill: '#BFBEBE',
          top: 207,
          left: 692
        },
        {
          type: 'path',
          path: 'M258.714 166.792H269.71V167.791H258.714V166.792Z',
          fill: '#BFBEBE',
          top: 204,
          left: 692
        },
        {
          type: 'path',
          path: 'M258.714 163.795H269.71V164.794H258.714V163.795Z',
          fill: '#BFBEBE',
          top: 201,
          left: 692
        },
        {
          type: 'path',
          path: 'M279.705 187.771H290.701V188.77H279.705V187.771Z',
          fill: '#BFBEBE',
          top: 225,
          left: 713
        },
        {
          type: 'path',
          path: 'M279.705 184.774H290.701V185.773H279.705V184.774Z',
          fill: '#BFBEBE',
          top: 222,
          left: 713
        },
        {
          type: 'path',
          path: 'M279.705 181.777H290.701V182.776H279.705V181.777Z',
          fill: '#BFBEBE',
          top: 219,
          left: 713
        },
        {
          type: 'path',
          path: 'M279.705 178.78H290.701V179.779H279.705V178.78Z',
          fill: '#BFBEBE',
          top: 216,
          left: 713
        },
        {
          type: 'path',
          path: 'M279.705 175.783H290.701V176.782H279.705V175.783Z',
          fill: '#BFBEBE',
          top: 213,
          left: 713
        },
        {
          type: 'path',
          path: 'M279.705 172.786H290.701V173.785H279.705V172.786Z',
          fill: '#BFBEBE',
          top: 210,
          left: 713
        },
        {
          type: 'path',
          path: 'M279.705 169.789H290.701V170.788H279.705V169.789Z',
          fill: '#BFBEBE',
          top: 207,
          left: 713
        },
        {
          type: 'path',
          path: 'M279.705 166.792H290.701V167.791H279.705V166.792Z',
          fill: '#BFBEBE',
          top: 204,
          left: 713
        },
        {
          type: 'path',
          path: 'M279.705 163.795H290.701V164.794H279.705V163.795Z',
          fill: '#BFBEBE',
          top: 201,
          left: 713
        },
        {
          type: 'path',
          path: 'M300.697 187.771H311.692V188.77H300.697V187.771Z',
          fill: '#BFBEBE',
          top: 225,
          left: 734
        },
        {
          type: 'path',
          path: 'M300.697 184.774H311.692V185.773H300.697V184.774Z',
          fill: '#BFBEBE',
          top: 222,
          left: 734
        },
        {
          type: 'path',
          path: 'M300.697 181.777H311.692V182.776H300.697V181.777Z',
          fill: '#BFBEBE',
          top: 219,
          left: 734
        },
        {
          type: 'path',
          path: 'M300.697 178.78H311.692V179.779H300.697V178.78Z',
          fill: '#BFBEBE',
          top: 216,
          left: 734
        },
        {
          type: 'path',
          path: 'M300.697 175.783H311.692V176.782H300.697V175.783Z',
          fill: '#BFBEBE',
          top: 213,
          left: 734
        },
        {
          type: 'path',
          path: 'M300.697 172.786H311.692V173.785H300.697V172.786Z',
          fill: '#BFBEBE',
          top: 210,
          left: 734
        },
        {
          type: 'path',
          path: 'M300.697 169.789H311.692V170.788H300.697V169.789Z',
          fill: '#BFBEBE',
          top: 207,
          left: 734
        },
        {
          type: 'path',
          path: 'M300.697 166.792H311.692V167.791H300.697V166.792Z',
          fill: '#BFBEBE',
          top: 204,
          left: 734
        },
        {
          type: 'path',
          path: 'M300.697 163.795H311.692V164.794H300.697V163.795Z',
          fill: '#BFBEBE',
          top: 201,
          left: 734
        },
        {
          type: 'path',
          path: 'M67 0H0.5L24.5 24.5L322.5 72.0773L396.847 298.748H450.193V312.666H479.109V341H528.965V317.14L991.631 324.099L1010 340.5L1034.5 310H980.5L987 317L556.885 309.187V274.888H450.193V287.812H405.322L330.5 63.1297L44.5 24.5L67 0Z',
          fill: '#00CDFB',
          left: 105,
          top: 86
        },
        {
          type: 'path',
          path: 'M0.733582 83.6788H73.1454V12.6794',
          stroke: '#FF2721',
          strokeWidth: 4,
          strokeLinejoin: 'round',
          fill: 'transparent',
          top: 242,
          left: 643
        },
        {
          type: 'circle',
          radius: 10,
          fill: '#FF2721',
          originX: 'center',
          originY: 'center',
          top: 242,
          left: 718
        },
        {
          type: 'rect',
          width: 75,
          height: 48,
          top: 291,
          left: 570,
          fill: '#626466',
          originX: 'left',
          originY: 'top'
        },
        {
          type: 'path',
          path: 'M38.7518 48.6544H52.746V115.586L45.7489 127.574L38.7518 115.586V48.6544Z',
          fill: '#626466',
          top: 339,
          left: 601
        },
        {
          type: 'path',
          path: 'M25.3789 79.2169C23.3303 78.8342 21.0972 78.6239 18.76 78.6239C8.823 78.6239 0.767456 82.4256 0.767456 87.1152C0.767456 91.8049 8.823 95.6066 18.76 95.6066C20.3632 95.6066 21.9174 95.5076 23.3967 95.3219C18.7334 93.4897 15.7612 90.7174 15.7612 87.6147C15.7612 84.0923 19.592 80.9957 25.3789 79.2169Z',
          fill: '#626466',
          top: 371,
          left: 563
        },
        {
          type: 'path',
          path: 'M65.7407 79.2169C67.7894 78.8342 70.0225 78.6239 72.3597 78.6239C82.2967 78.6239 90.3522 82.4256 90.3522 87.1152C90.3522 91.8049 82.2967 95.6066 72.3597 95.6066C70.7565 95.6066 69.2023 95.5076 67.723 95.3219C72.3863 93.4897 75.3585 90.7174 75.3585 87.6147C75.3585 84.0923 71.5277 80.9957 65.7407 79.2169Z',
          fill: '#626466',
          top: 371,
          left: 630
        },
        {
          type: 'path',
          path: 'M43.7497 80.6218C30.8291 80.6218 18.76 83.1429 18.76 87.4852C18.76 91.8274 30.4594 94.1081 43.7497 94.1081V80.6218Z',
          fill: '#626466',
          top: 373,
          left: 581
        },
        {
          type: 'path',
          path: 'M46.7485 80.6218C60.0387 80.6218 71.7381 83.1429 71.7381 87.4852C71.7381 91.8274 60.0387 94.1081 46.7485 94.1081V80.6218ZM23.6099 87.5021C23.6099 87.4964 23.6098 87.4908 23.6098 87.4852C23.6098 87.4796 23.6099 87.4739 23.6099 87.4683V87.5021Z',
          fill: '#626466',
          top: 373,
          left: 590
        },
        {
          type: 'rect',
          width: 11,
          height: 38,
          rx: 5.50,
          top: 295,
          left: 577,
          fill: '#DB9B2E',
          originX: 'left',
          originY: 'top'
        },
        {
          type: 'rect',
          width: 11,
          height: 38,
          rx: 5.50,
          top: 295,
          left: 593,
          fill: '#DB9B2E',
          originX: 'left',
          originY: 'top'
        },
        {
          type: 'rect',
          width: 11,
          height: 38,
          rx: 5.50,
          top: 295,
          left: 610,
          fill: '#DB9B2E',
          originX: 'left',
          originY: 'top'
        },
        {
          type: 'rect',
          width: 11,
          height: 38,
          rx: 5.50,
          top: 295,
          left: 628,
          fill: '#DB9B2E',
          originX: 'left',
          originY: 'top'
        }
      ] */
    },
    modal: (props) => <MountainContent {...props} />,
    onReceive: (thisDraw, config) => {
      if (config.data && config.data.currentText) {
        thisDraw.text = config.data.currentText
      }
      return thisDraw
    },
    event: {
      onLoad: (thisDraw, config) => {
        if (config.data && config.data.currentText) {
          thisDraw.text = config.data.currentText
        }
        return thisDraw
      },
      mouseDown: () => { },
      mouseOver: () => { }
    }
  }
}
