import { useState } from 'react'
import clsx from 'clsx'
import { Box, Drawer, ListItem, ListItemText, makeStyles, Tab, Tabs, Typography, List, Avatar, Divider, ListItemSecondaryAction, IconButton, Tooltip, Button, Step, Stepper, StepLabel, Grid } from '@material-ui/core'
import { basicColors } from '@/theme'
import { Send as SendIcon, Copy as CopyIcon, Settings as SettingsIcon, Trash as TrashIcon } from 'react-feather'
import StepperConnector from '@/components/CustomStepper'
import FiltersContent from './FiltersContent'
import useAuth from '@/hooks/useAuth'
import { formatPeriod, getDashboardTypes } from './FiltersContent/utils'
import moment from 'moment'
import ConfirmationDialogRaw from '@/components/ConfirmationDialogRaw'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 560,
    maxWidth: '100%'
  },
  filterHeader: {
    height: 64,
    display: 'flex',
    alignItems: 'flex-end',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.dark,
    textTransform: 'uppercase'
  },
  divider: {
    backgroundColor: basicColors.blueGrayLight
  },
  verticalDivider: {
    backgroundColor: basicColors.blueGrayLight,
    margin: theme.spacing(0, 0.5)
  },
  listItem: {
    fontSize: 16,
    width: 28,
    height: 28,
    marginRight: 16
  }
}))

function SidebarFilters ({ plantId, editFilters, deleteFilter, saveFilters, devices, applyFilters, className, open, onClose, ...rest }) {
  const classes = useStyles()
  const { user } = useAuth()
  // Valore di stato per le tab dei filtri
  const [currentTab, setCurrentTab] = useState(0)
  // Valore di stato per lo step durante la fase di creazione di un filtro
  const [activeStep, setActiveStep] = useState(0)
  // Valore di stato della data iniziale
  const [startDate, setStartDate] = useState(null)
  // Valore di stato della data finale
  const [endDate, setEndDate] = useState(null)
  // Valore di stato per i titoli degli step
  const [steps, setSteps] = useState(['Tipo di grafico', 'Periodo', 'Elementi'])
  // Tipo di visualizzazione selezionato
  const [dashboardType, setDashboardType] = useState('trend')
  // variabile che apre o chiude la dialog di conferma
  const [openConfirm, setOpenConfirm] = useState(false)
  // variabile che contiene il filtro selezionato per la modifica/eliminazione
  const [selectedFilter, setSelectedFilter] = useState(null)
  // valore di base dei filtri
  const [filters, setFilters] = useState({
    deviation: [{ show: true, elements: [] }],
    // stringAnalysis: [{ show: true, elements: [] }, { show: false, elements: [] }, { show: false, elements: [] }, { show: false, elements: [] }],
    stringAnalysis: [{ show: true, elements: [] }],
    trend: [{ show: true, elements: [] }, { show: false, elements: [] }]
  })

  // Funzione eseguita alla chiusura del menu di creazione filtri personalizzati
  const handleClose = () => {
    onClose()
  }

  // Funzione eseguita al cambiamento di tab
  const handleTabChange = (e, newValue) => {
    setCurrentTab(Number(newValue))
  }

  // Funzione che prende in ingresso un filtro e ritorna la stringa di descrizione
  const getDescription = (filter) => {
    const startDate = moment(filter.startDate).format('D MMMM')
    const endDate = moment(filter.endDate).format('D MMMM')
    const typeObj = getDashboardTypes().find(el => el.value === filter.type)
    const type = typeObj ? typeObj.label : ''

    return `${type} dal ${startDate} al ${endDate}`
  }

  const handleDeleteConfirm = async (confirmDelete) => {
    if (confirmDelete) {
      await deleteFilter(selectedFilter.uuid)
      setSelectedFilter(null)
    } else {
      setSelectedFilter(null)
    }

    setOpenConfirm(false)
  }

  // Funzione che setta tutti i parametri per visualizzare la schermata di modifica
  const setupEditFilter = (filter) => {
    setSelectedFilter(filter)
    setStartDate(moment(filter.startDate))
    setEndDate(moment(filter.endDate))
    setDashboardType(filter.type)
    setFilters(filter)
  }

  // Funzione che resetta lo stato delle tab allo stato iniziale
  const resetFilter = () => {
    setActiveStep(0)
    setSelectedFilter(null)
    setDashboardType('deviation')
    setSteps(['Tipo di grafico', 'Periodo', 'Elementi'])
    setStartDate(null)
    setEndDate(null)
  }

  return (
    <Drawer
      {...rest}
      className={clsx(classes.root, className)}
      anchor='right'
      classes={{ paper: classes.drawer }}
      variant='temporary'
      open={open}
      onClose={handleClose}
    >
      <Tabs
        className={classes.filterHeader}
        value={currentTab}
        onChange={handleTabChange}
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
      >
        <Tab label='Nuovo Filtro' />
        <Tab label='Filtri Salvati' />
      </Tabs>
      {currentTab === 0 &&
        <Box value={currentTab} height='100%' width='100%' py={4} px={2}>
          <Typography variant='body2'>Segui la procedura per specificare gli elementi da graficare</Typography>
          <Box width='100%' mt={2}>
            <Stepper
              connector={<StepperConnector />}
              activeStep={activeStep}
              alternativeLabel
            >
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </Box>
          <Box width='100%' mt={2}>
            <FiltersContent
              setCurrentTab={setCurrentTab}
              resetFilter={resetFilter}
              isEdit={!!selectedFilter}
              dashboardType={dashboardType}
              setDashboardType={setDashboardType}
              saveFilters={selectedFilter ? editFilters : saveFilters}
              applyFilters={applyFilters}
              filters={filters}
              setFilters={setFilters}
              devices={devices}
              steps={steps}
              setSteps={setSteps}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              closeAction={onClose}
            />
          </Box>
        </Box>}
      {currentTab === 1 &&
        <Box value={currentTab} height='100%' width='100%' py={4} px={2}>
          <Typography variant='body2'>Scegli uno dei filtri salvati da applicare</Typography>
          <Box width='100%' mt={2}>
            {openConfirm
              ? (
                <ConfirmationDialogRaw
                  open={openConfirm}
                  onClose={handleDeleteConfirm}
                  title='Conferma eliminazione Filtro'
                  description={`Sei sicuro di voler eliminare il filtro "${(selectedFilter && selectedFilter.name) || ''}"?`}
                />
                )
              : null}
            <List component='nav'>
              {user.metadata.analyticsFilters && user.metadata.analyticsFilters.filter(el => el.plantId === plantId).length > 0
                ? user.metadata.analyticsFilters.filter(el => el.plantId === plantId).map((el, elIndex) => (
                  <div key={el.uuid}>
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <Avatar className={classes.listItem}>{elIndex + 1}</Avatar>
                      <Tooltip title={el.name || `Configurazione ${elIndex + 1}`}>
                        <ListItemText
                          primaryTypographyProps={{ noWrap: true, style: { width: 270 } }}
                          primary={el.name || `Configurazione ${elIndex + 1}`}
                          secondary={getDescription(el)}
                        />
                      </Tooltip>
                      <ListItemSecondaryAction>
                        <Box width='100%' display='flex' alignItems='center'>
                          <Tooltip title='Applica'>
                            <IconButton
                              onClick={() => {
                                // applyFilters(el, el.type, formatPeriod(moment(el.startDate), moment(el.endDate)))
                                applyFilters(el, el.type, el.startDate, el.endDate)
                                onClose()
                              }} style={{ margin: '0 4px' }} size='small' edge='start'
                            >
                              <SendIcon color={basicColors.primary} size={20} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Duplica'>
                            <IconButton
                              onClick={() => saveFilters(el, `${el.name} (Copia)`, el.type, moment(el.startDate), moment(el.endDate))}
                              style={{ margin: '0 4px' }} size='small'
                            >
                              <CopyIcon color={basicColors.blueGrayLight} size={20} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Modifica'>
                            <IconButton
                              onClick={() => {
                                setupEditFilter(el)
                                setCurrentTab(0)
                                setSteps(prevSteps => {
                                  const newSteps = [...prevSteps].map((step, stepIndex) => {
                                    let currentString = ''
                                    if (stepIndex === 0) {
                                      const currentTitle = getDashboardTypes().find(dashboard => dashboard.value === el.type)
                                      if (currentTitle) {
                                        currentString = currentTitle.label
                                      }
                                    } else if (stepIndex === 1) {
                                      if (el.startDate && el.endDate) {
                                        currentString = `${formatPeriod(moment(el.startDate), moment(el.endDate))}`
                                      } else {
                                        currentString = 'Periodo'
                                      }
                                    } else {
                                      currentString = step
                                    }

                                    return currentString
                                  })

                                  return newSteps
                                })
                              }}
                              style={{ margin: '0 4px' }}
                              size='small'
                            >
                              <SettingsIcon color={basicColors.darkYellow} size={20} />
                            </IconButton>
                          </Tooltip>
                          <Divider className={classes.verticalDivider} flexItem orientation='vertical' />
                          <Tooltip title='Elimina'>
                            <IconButton
                              onClick={() => {
                                setSelectedFilter(el)
                                setOpenConfirm(true)
                              }} style={{ margin: '0 4px' }} size='small' edge='end'
                            >
                              <TrashIcon color={basicColors.darkRed} size={20} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider className={classes.divider} />
                  </div>
                  ))
                : (
                  <Grid container justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 40 }}>
                    <Grid style={{ textAlign: 'center' }}>
                      <img
                        src='/static/images/undraw_no_data_qbuo.svg'
                        style={{ width: '60%', marginBottom: 16 }}
                        alt='No filters Illustration'
                      />
                      <Typography variant='body1' align='center'>
                        Non hai salvato nessun filtro, creane uno nuovo e salvalo per riutilizzarlo
                      </Typography>
                    </Grid>
                  </Grid>
                  )}
            </List>
            <Box mt={4} width='100%' display='flex' alignItems='center' justifyContent='space-between'>
              <Button onClick={onClose}>Chiudi</Button>
              <Button
                onClick={() => {
                  resetFilter()
                  setCurrentTab(0)
                }} variant='contained' color='primary'
              >Nuovo
              </Button>
            </Box>
          </Box>
        </Box>}
    </Drawer>
  )
}
export default SidebarFilters
