import { libraryDevices } from '@/components/Planimetry/libraryDevices'

const defined = (value) => value !== null && value !== undefined

/*
 * Funzione che prende in ingresso l'oggetto di risposta del backend e restituisce un oggetto
 * contenente gli oggetti nel formato utile al frontend per consentire le interazioni con l'interfaccia
 *
 * Input: {
 *    assets: [],
 *    assetTypes: [],
 *    plant: {},
 *    devices: [],
 *    dataloggers: []
 *  }
 *
 * Output: {
 *    plant: {}
 *  }
 */

export const decodeFromApi = (apiResponse) => {
  // Singoli elementi che serviranno per assemblare l'oggetto ritornato per il frontend
  const { plant, devices, dataloggers, assets = [], assetTypes = [] } = apiResponse || {}
  // Estrapolo le informazioni dall'oggetto 'plant' per preparare i dati per lo store redux
  const { name, uuid, metadata, location } = plant || {}

  // Se è definito solo location ma non è presente l'oggetto address lo prepopolo
  if (location?.coordinates && !metadata?.address) {
    metadata.address = {
      type: 'point',
      inputValue: '',
      coordinates: location.coordinates
    }
  }

  if (!defined(metadata.addedDataloggers)) {
    metadata.addedDataloggers = dataloggers.map(datalogger => ({ name: datalogger.name, uuid: datalogger.uuid }))
  }

  if (!defined(metadata.loadData)) {
    metadata.loadData = false
  }

  if (!defined(metadata.maintenance)) {
    metadata.maintenance = false
  }

  if (!defined(metadata.hasAutosave)) {
    metadata.hasAutosave = false
  }

  if (!defined(metadata.displayOverview)) {
    metadata.displayOverview = 'planimetry'
  }

  if (!defined(metadata.documents)) {
    metadata.documents = []
  }

  if (!defined(metadata.peakPower)) {
    metadata.peakPower = ''
  }

  if (!defined(metadata.anagraphic)) {
    metadata.anagraphic = {
      peakPower: '',
      plantCode: '',
      contractType: '',
      referentName: '',
      referentClient: '',
      referentRole: '',
      referentEmail: '',
      referentPhone: '',
      monitoringName: '',
      monitoringEmail: '',
      monitoringPhone: '',
      operationAndMaintenaceName: '',
      operationAndMaintenacePhone: '',
      operationAndMaintenaceEmail: ''
    }
  }

  if (!defined(metadata.startDate)) {
    metadata.startDate = null
  }

  if (!defined(metadata.endDate)) {
    metadata.endDate = null
  }

  if (!defined(metadata.contractDuration)) {
    metadata.contractDuration = 1
  }

  if (!defined(metadata.mapConfig)) {
    metadata.mapConfig = {
      zoom: 20,
      center: {
        lat: 0,
        lng: 0
      }
    }
  }

  if (!defined(metadata.planimetry)) {
    metadata.planimetry = {
      draw: [],
      config: []
    }
  }

  if (metadata?.planimetry?.draw?.length === 0) {
    metadata.planimetry = { draw: [], config: [] }
  }

  if (!defined(metadata.logoURL)) {
    metadata.logoURL = null
  }

  const { planimetry } = metadata

  // Aggiungo ai dataloggers ricevuti l'informazione dei devices associati ad essi
  const addedDataloggers = dataloggers.map((datalogger) => {
    // Prendo tutti i devices con nodeId uguale all'uuid del datalogger
    const dataloggerDevices = devices.filter((device) => device.nodeId === datalogger.uuid)
    // console.log('decodeFromApi - dataloggerDevices: ', dataloggerDevices)
    return {
      ...datalogger,
      includes: {
        devices: dataloggerDevices
      }
    }
  })

  // Ricostruisco la config della planimetry basandomi sui dati ricevuti dalle risorse primarie
  const newConfig = planimetry?.config?.map((configEl) => {
    let newConfigEl = {}
    const { id, uuid, canvasLibraryType, data, additionalData } = configEl
    // se sto ciclando un elemento di config di un generatore
    if (configEl.canvasLibraryType === 'obstacle') {
      newConfigEl = configEl
    } else {
      const newDevices = devices
        // filtro i devices prendendo solo quelli con uuid uguale all'uuid della config
        .filter((device) => device.uuid === uuid && device.deviceType.category.toLowerCase() !== 'generator')
        .map((device) => {
          // prendo il modello del device
          const deviceModel = device.deviceType.model
          // popolo l'oggetto properties per ogni device partendo dalle properties del device
          const properties = {}
          const libraryDevice = libraryDevices.find((libEl) => libEl.models.includes(deviceModel))
          if (libraryDevice) {
            const deviceKeys = Object.keys(libraryDevice)
            deviceKeys
              .filter((key) => key !== 'models')
              .forEach((key) => {
                properties[key] = {
                  label: libraryDevice[key],
                  value: 0
                }
              })
          }

          const deviceEl = {
            type: device.deviceType.category.toLowerCase(),
            model: device.deviceType.model,
            deviceId: device.uuid,
            // sto assegnando le properties dai devices, ma credo che per il momento dovrò prenderle dal file di libreria
            properties,
            additionalData: {}
          }

          return deviceEl
        })

      const newAssets = assets
        ?.filter(asset => asset.uuid === uuid)
        ?.map(asset => {
          const assetEl = {
            type: 'sensor',
            assetId: asset.uuid,
            properties: asset?.assetType?.properties,
            additionalData: {}
          }

          return assetEl
        })

      newConfigEl = {
        id,
        uuid,
        canvasLibraryType,
        data: {
          draw: (data && data.draw) || {}
        },
        additionalData,
        devices: newDevices,
        assets: newAssets
      }
    }

    return newConfigEl
  })

  const newPlanimetry = {
    draw: (planimetry && planimetry.draw) || [],
    config: newConfig || []
  }

  const resultObject = {
    plant: {
      ...metadata,
      name,
      uuid,
      planimetry: newPlanimetry,
      addedDataloggers,
      devices,
      assets,
      assetTypes
    }
  }

  return resultObject
}

/*
  * Funzione che prende in ingresso l'oggetto 'plant' presente nello store e restituisce un oggetto
  * che potrà essere passato come body alla chiamata POST al backend
  *
  * Input: {
      name: '',
      uuid: '', // solo in caso si tratti di una modifica
      addedDataloggers: [],
      address: {}
      endDate: '',
      startDate: '',
      maintenance: true | false,
      manufacturability: {},
      peakPower: 0,
      planimetry: {},
      plantType: ''
  *  }
  *
  * Output: {
  *    plant: {},
  *  }
*/
export const encodeForApi = (plantStore) => {
  // Estrapolo l'array degli orientamenti, l'array dei generatori e l'oggetto impianto dallo store
  const plant = { ...plantStore }

  const planimetry = { ...plant.planimetry }

  // Bisogna cancellare i campi 'devices' e 'data.orientations' dai singoli elementi di config
  const newConfig = planimetry.config.map((configEl) => {
    // se l'elemento di config ha devices li cancello
    if (configEl.devices && configEl.devices !== undefined) {
      configEl.devices = []
    }
    // se l'elemento di config ha gli assets li cancello
    if (configEl.assets && configEl.assets !== undefined) {
      configEl.assets = []
    }

    return configEl
  })

  // creo il nuovo oggetto planimetry con la nuova config senza orientamenti e devices
  const newPlanimetry = {
    draw: planimetry.draw,
    config: newConfig
  }

  // Rimuovo i campi che non servono al backend da plant
  delete plant.dataloggers
  delete plant.assetTypes
  delete plant.assets
  delete plant.plants
  delete plant.activeStep
  delete plant.selectedDatalogger

  // Trasformo le date da stringhe formattate a ISOString per passarle al backend
  if (plant.startDate) {
    plant.startDate = new Date(
      plant.startDate
        .split('/')
        .reverse()
        .join('-')
    ).toISOString()
  }
  if (plant.endDate) {
    plant.endDate = new Date(
      plant.endDate
        .split('/')
        .reverse()
        .join('-')
    ).toISOString()
  }
  // Converto la potenza di picco in numero per poter eseguire i calcoli più agilmente lato backend
  if (plant.peakPower) {
    plant.peakPower = Number(plant.peakPower)
  }

  const { name, uuid, ...metadata } = plant

  // Passo solo id e nome dei dataloggers associati all'impianto
  const addedDataloggers = metadata.addedDataloggers.map(el => ({ uuid: el.uuid, name: el.name }))

  // Filtro solo le info di configurazione che servono al backend
  const removableMetadataKeys = ['loadData', 'generators', 'plantSummaryStatus', 'plantSummaryUrl', 'weather']
  const restMetadata = { ...metadata.rest } || {}
  const backendConfigMetadata = Object.keys(restMetadata).filter(key => !removableMetadataKeys.includes(key)).reduce((acc, key) => {
    acc[key] = restMetadata[key]
    return acc
  }, {})

  // Rimuovo l'oggetto temporaneo per le configurazioni aggiuntive in metadata
  delete metadata.rest

  const newPlant = {
    plant: {
      name,
      metadata: {
        ...metadata,
        addedDataloggers,
        anagraphic: {
          peakPower: '',
          plantCode: '',
          contractType: '',
          referentName: '',
          referentClient: '',
          referentRole: '',
          referentEmail: '',
          referentPhone: '',
          monitoringName: '',
          monitoringEmail: '',
          monitoringPhone: '',
          operationAndMaintenaceName: '',
          operationAndMaintenacePhone: '',
          operationAndMaintenaceEmail: '',
          ...metadata.anagraphic
        },
        planimetry: newPlanimetry,
        ...backendConfigMetadata
      }
    }
  }

  // Se l'impianto ha un uuid lo inserisco nell'oggetto plant
  if (uuid && uuid !== undefined) {
    newPlant.plant.uuid = uuid
  }

  return newPlant
}
