import { useState } from 'react'
import clsx from 'clsx'
import { Box, Button, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import moment from 'moment'
import TrendContent from './TrendContent'
import { formatPeriod, getDashboardTypes } from './utils'
import FilterNameDialog from './FilterNameDialog'
import useAuth from '@/hooks/useAuth'
import log from '@pelv/frontlog'
import { getAggregationTypeFromPeriod } from '../../../utils'

const useStyles = makeStyles(() => ({
  root: {}
}))

function FiltersContent ({
  devices,
  resetFilter,
  setCurrentTab,
  filters,
  applyFilters,
  setFilters,
  saveFilters,
  dashboardType,
  setDashboardType,
  isEdit = null,
  setSteps = null,
  steps = [],
  startDate = null,
  endDate = null,
  setStartDate,
  setEndDate,
  closeAction = null,
  activeStep,
  setActiveStep,
  className
}) {
  const classes = useStyles()

  const { user } = useAuth()

  // variabile di apertura/chiusura della modal per le properties di un elemento
  const [openPropertiesModal, setOpenPropertiesModal] = useState(false)
  // variabile di apertura/chiusura della modal per il nome del filtro al momento del salvataggio
  const [openFilterNameDialog, setOpenFilterNameDialog] = useState(false)

  // funzione che ritorna il contenuto della sezione elementi in base al tipo di visualizzazione utilizzata
  const getElementsStepContent = (viewType) => {
    switch (viewType) {
      case 'trend': {
        return (
          <TrendContent
            startDate={startDate}
            endDate={endDate}
            filters={filters}
            setFilters={setFilters}
            viewType={viewType}
            devices={devices}
            openPropertiesModal={openPropertiesModal}
            setOpenPropertiesModal={setOpenPropertiesModal}
          />
        )
      }

      default: {
        return null
      }
    }
  }

  // funzione che ritorna il contenuto dello step attuale
  function getStepContent (step) {
    switch (step) {
      case 0:
        return (
          <Box width='100%'>
            <Typography variant='body2'>Scegliere il tipo di grafico che si vuole visualizzare</Typography>
            <Box mt={2}>
              <TextField onChange={(e) => setDashboardType(e.target.value)} value={dashboardType} size='small' variant='outlined' fullWidth select>
                {getDashboardTypes().map(el => (
                  <MenuItem key={el.value} value={el.value}>
                    {el.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        )
      case 1:
        return (
          <Box width='100%'>
            <Typography variant='body2'>Scegliere il periodo da visualizzare</Typography>
            <Box mt={2} display='flex' alignItems='center'>
              <Box mr={1} width='100%'>
                <KeyboardDateTimePicker
                  fullWidth
                  className={classes.datePicker}
                  label='Data inizio'
                  size='small'
                  KeyboardButtonProps={{ size: 'small' }}
                  format='DD/MM/YYYY HH:mm'
                  name='currentPeriod'
                  disableFuture
                  views={['date', 'hours']}
                  ampm={false}
                  inputVariant='outlined'
                  value={startDate}
                  onChange={(date) => {
                    setStartDate(date)
                  }}
                />
              </Box>
              <KeyboardDateTimePicker
                fullWidth
                className={classes.datePicker}
                label='Data fine'
                size='small'
                KeyboardButtonProps={{ size: 'small' }}
                format='DD/MM/YYYY HH:mm'
                name='currentPeriod'
                disableFuture
                views={['date', 'hours']}
                ampm={false}
                inputVariant='outlined'
                value={endDate}
                onChange={(date) => {
                  setEndDate(date)
                }}
              />
            </Box>
          </Box>
        )
      case 2:
        return (
          <Box pb={5} width='100%'>
            {getElementsStepContent(dashboardType)}
          </Box>
        )
      default:
        return ''
    }
  }

  return (
    <div className={clsx(classes.root, className)}>
      {getStepContent(activeStep)}
      <Box pt={5} pb={2} display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex'>
          <Button
            disabled={activeStep === 0}
            onClick={() => {
              setActiveStep(prevActiveStep => (Number(prevActiveStep) - 1))
            }}
          >
            Indietro
          </Button>
        </Box>
        <Box display='flex'>
          {
            // activeStep === (steps.length - 1) && !isEdit
            activeStep === (steps.length - 1)
              ? (
                <Button
                  onClick={() => {
                    log({ text: 'filters => ', variable: filters, tag: 'applyfilters' })
                    applyFilters(filters, dashboardType, startDate, endDate)
                    closeAction()
                  }}
                  variant='outlined'
                  color='primary'
                  size='small'
                  disabled={
                    // se non ho selezioanto almeno un elemento
                    !(filters[dashboardType].some(el => (el.elements && el.elements.length > 0))) ||
                    // se non ho deciso il tipo di aggregazione
                    (dashboardType === 'trend' && !(filters[dashboardType].filter(el => el.show === true).every(el => (el.aggregationType && el.aggregationType !== undefined))))
                  }
                >
                  Applica
                </Button>
                )
              : activeStep !== (steps.length - 1)
                ? (
                  <Button
                    disabled={(activeStep === 0 && !dashboardType) || (activeStep === 1 && (!startDate || !endDate))}
                    onClick={() => {
                      if (activeStep === 1 && startDate && endDate) {
                        setFilters(prevFilters => {
                          const trendFilters = prevFilters.trend.map(el => ({
                            ...el,
                            aggregationType: el.aggregationType || getAggregationTypeFromPeriod(startDate, endDate)
                          }))

                          return {
                            ...prevFilters,
                            trend: trendFilters
                          }
                        })
                      }
                      setActiveStep(prevActiveStep => (Number(prevActiveStep) + 1))
                      setSteps(prevSteps => {
                        const newSteps = [...prevSteps]
                        if (activeStep === 0) {
                          const currentTitle = getDashboardTypes().find(el => el.value === dashboardType)
                          if (currentTitle) {
                            newSteps[activeStep] = currentTitle.label
                          }
                        } else if (activeStep === 1) {
                          if (startDate && endDate) {
                            newSteps[activeStep] = `${formatPeriod(moment(startDate), moment(endDate))}`
                          } else {
                            newSteps[activeStep] = 'Periodo'
                          }
                        }

                        return newSteps
                      })
                    }}
                    variant='contained'
                    color='primary'
                    size='small'
                  >
                    Avanti
                  </Button>
                  )
                : null
          }
          <Box ml={1}>
            {openFilterNameDialog
              ? (
                <FilterNameDialog
                  name={isEdit && filters && filters.name ? filters.name : null}
                  filtersLength={(user.metadata.analyticsFilters && user.metadata.analyticsFilters.length) || 0}
                  onConfirm={(name) => {
                    saveFilters(filters, name, dashboardType, startDate, endDate)
                    resetFilter()
                    setCurrentTab(1)
                    setOpenFilterNameDialog(false)
                  }}
                  open={openFilterNameDialog}
                  onClose={() => setOpenFilterNameDialog(false)}
                />
                )
              : null}
            <Button
              disabled={
                // se non sono all'ultimo step
                activeStep !== (steps.length - 1) ||
                // se non ho selezioanto almeno un elemento
                !(filters[dashboardType].some(el => (el.elements && el.elements.length > 0))) ||
                // se non ho deciso il tipo di aggregazione
                (dashboardType === 'trend' && !(filters[dashboardType].filter(el => el.show === true).every(el => (el.aggregationType && el.aggregationType !== undefined))))
              }
              onClick={() => setOpenFilterNameDialog(true)}
              variant='contained' color='primary' size='small'
            >
              Salva
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
export default FiltersContent
