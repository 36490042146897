import { memo, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { configuration } from '@/config'
import {
  GoogleMap,
  useJsApiLoader,
  MarkerClusterer,
  Marker,
  InfoWindow
} from '@react-google-maps/api'
import LoadingCard from '../LoadingCard'
import { controlPositions, convertBoundsToCenterPoint, convertMarkersToClusterTooltipData, markerStyles } from './utils'

import ClusterPopover from '@/views/overview/MapsView/ClusterPopover'

// Chiave API di Google Maps
const { googleMapsApiKey } = configuration

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}))

const containerStyle = {
  width: '100%',
  height: '100%',
  outline: 0
}

const clusterOptions = {
  imagePath:
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
}

// Componente Mappa implementato utilizzando le API di google-maps
function GMap ({
  fit = false,
  bounds = [],
  handleDrag = null,
  handleZoom = null,
  showMarker = true,
  showZoomControls = false,
  zoom: zoomProp = 6,
  markers = null,
  draggable = false,
  onDragEnd,
  lat = 41.896187,
  lng = 12.492046,
  className,
  ...rest
}) {
  // Hook che configura il componente con l'API key di google
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey
  })

  const options = {
    minZoom: 6,
    gestureHandling: 'auto',
    // gestureHandling: 'cooperative',
    zoomControl: showZoomControls,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    // mapTypeId: 'terrain',
    zoomControlOptions: {
      position: controlPositions.TOP_LEFT
    }
  }

  // Variabile che contiene il punto centrale della mappa in base ai bounds passati come parametro
  const [centerPoint, setCenterPoint] = useState(markers && bounds ? convertBoundsToCenterPoint(bounds, 3) : { lat, lng })
  // Variabile che determina lo zoom della mappa
  const [zoom, setZoom] = useState(zoomProp)
  // Variabile che determina se aprire o meno il popup
  const [showPopup, setShowPopup] = useState(false)
  // Variabile che determina se aprire o meno il popup del cluster
  const [showClusterPopup, setShowClusterPopup] = useState(false)
  // Variabile che contiene il marker attualmente selezionato
  const [currentMarker, setCurrentMarker] = useState(null)
  // variabile che contiene i dati da rappresentare nel tootlip dei cluster
  const [clusterTooltipData, setClusterTooltipData] = useState([])
  // Variabile che contiene latitudine e longitudine del marker che rappresenta il cluster
  const [clusterLatLng, setClusterLatLng] = useState(null)

  const classes = useStyles()

  const mapRef = useRef(null)

  useEffect(() => {
    if (fit) {
      setZoom(10)
      const center = convertBoundsToCenterPoint(bounds)
      setCenterPoint(center)
    }
  }, [bounds, fit])

  // funzione che definisce quale infoWindow mostrare
  const displayPopup = (markerKey) => {
    // Nascondo il popup del cluster di impianti se è aperto
    if (showClusterPopup) {
      hideClusterPopup()
    }
    setShowPopup(true)
    setCurrentMarker(markerKey)
  }

  // Funzione che nasconde l'infoWindow correntemente mostrata
  const hidePopup = () => {
    setShowPopup(false)
    setCurrentMarker(null)
  }

  // funzione che definisce quale infoWindow di cluster mostrare
  const displayClusterPopup = (event) => {
    // Nascondo il popup del singolo impianto se è aperto
    if (showPopup) {
      hidePopup()
    }
    // prendo latitudine e longitudine del marker che rappresenta il cluster
    const clusterLat = event.center.lat()
    const clusterLng = event.center.lng()
    // Formatto i dati per mostrare il popup dei cluster
    const clusterData = convertMarkersToClusterTooltipData(event.markers)
    setShowClusterPopup(true)
    setClusterLatLng({ lat: clusterLat, lng: clusterLng })
    setClusterTooltipData(clusterData)
  }

  // Funzione che nasconde l'infoWindow di cluster
  const hideClusterPopup = () => {
    setShowClusterPopup(false)
    setClusterLatLng(null)
    setClusterTooltipData([])
  }

  // Funzione che zoomma su un impianto sulla mappa
  const zoomToPoint = (plantLatLng) => {
    setCenterPoint(plantLatLng)
    setZoom(10)
    hideClusterPopup()
  }

  // funzione che nasconde tutti i popup
  const hideAllPopups = () => {
    const googleMap = mapRef.current
    // Se esite una ref della mappa
    if (googleMap) {
      if (showPopup) {
        hidePopup()
      }
      if (showClusterPopup) {
        hideClusterPopup()
      }
    }
  }

  const renderMap = () => {
    return (
      <div className={clsx(classes.root, className)}>
        <GoogleMap
          ref={mapRef}
          id='map-example'
          options={options}
          mapContainerStyle={containerStyle}
          center={centerPoint}
          zoom={zoom}
          onDragEnd={() => {
            const googleMap = mapRef.current
            if (googleMap && handleDrag) {
              const centerPoint = googleMap.state?.map?.center
              if (centerPoint) {
                const currentCenter = { lat: centerPoint.lat(), lng: centerPoint.lng() }
                handleDrag(currentCenter)
              }
            }
          }}
          onZoomChanged={() => {
            const googleMap = mapRef.current
            // Se esite una ref della mappa
            if (googleMap) {
              hideAllPopups()
              if (handleZoom) {
                const currentZoom = googleMap.state?.map?.zoom || zoomProp
                handleZoom(currentZoom)
              }
              // Mantengo in sync lo zoom dello state con quello modificato dai tasti di controllo dello zoom nativi della mappa
              /* const currentZoom = googleMap.state.map.zoom
              setZoom(currentZoom) */
            }
          }}
          onClick={hideAllPopups}
          onDrag={hideAllPopups}
          onMouseOut={hideAllPopups}
        >
          <MarkerClusterer
            averageCenter
            gridSize={30}
            options={clusterOptions}
            styles={markerStyles}
            calculator={(markers) => {
              const isRed = markers.find(el => el.icon?.url?.includes('red_marker'))
              const isYellow = markers.find(el => el.icon?.url?.includes('yellow_marker'))

              return { text: '', index: isRed ? 3 : isYellow ? 2 : 1 }
            }}
            onMouseOver={(e) => displayClusterPopup(e)}
          // onMouseOut={() => hideClusterPopup()}
          >
            {(clusterer) =>
              markers
                ? markers.map((location, index) => (
                  <Marker
                    key={`singleMarker${index}-${location.plantName}`}
                    position={{ lat: location.coordinates[1], lng: location.coordinates[0] }}
                    clusterer={clusterer}
                    onClick={() => location.onClick()}
                    options={{
                      status: location.status || '',
                      plantName: location.plantName || '',
                      onClick: location.onClick || null
                    }}
                    // onMouseOut={() => hidePopup()}
                    onMouseOver={() => displayPopup(`singleMarker${index}-${location.plantName}`)}
                    {...location.icon && location.icon ? ({ icon: location.icon }) : null}
                  >
                    {/* <OverlayView
                      position={{ lat: location.coordinates[1], lng: location.coordinates[0] }}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      {location.popup}
                    </OverlayView> */}
                    {location.popup && location.popup !== undefined && showPopup && currentMarker === `singleMarker${index}-${location.plantName}`
                      ? (
                        <InfoWindow>
                          {location.popup(hidePopup)}
                        </InfoWindow>
                        )
                      : null}
                  </Marker>
                  ))
                : showMarker
                  ? (
                    <Marker
                      draggable={draggable}
                      onDragEnd={onDragEnd}
                      // eventHandlers={eventHandlers}
                      position={{ lat, lng }}
                    />
                    )
                  : null}
          </MarkerClusterer>
          {showClusterPopup && clusterLatLng
            ? (
              <InfoWindow position={clusterLatLng}>
                <ClusterPopover onClose={hideClusterPopup} onItemClick={zoomToPoint} data={clusterTooltipData} />
              </InfoWindow>)
            : null}
        </GoogleMap>
      </div>
    )
  }

  if (loadError) {
    return <div>Errore durante il caricamento della mappa. Ricarica la pagina per riprovare</div>
  }

  return isLoaded
    ? renderMap()
    : <LoadingCard />
}
export default memo(GMap)
