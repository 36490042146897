import useAuth from '@/hooks/useAuth'
import { useClientRect } from '@/hooks/useClientRect'
import { createGuestHeaders } from '@/utils/createGuestHeaders'
import { Box, Typography, Card, Grid, LinearProgress, makeStyles, useMediaQuery } from '@material-ui/core'
import { memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import api from '@micmnt/apis'
import clsx from 'clsx'
import PeriodSelection from '@/components/PeriodSelection'
import InfoCard from '@/components/InfoCard'
import { Skeleton } from '@material-ui/lab'
import DownloadExcelButton from '../../shared/DownloadExcelButton'
import ShareButton from '@/components/ShareButton'
import { calculateCumulatedSerie, decodeCardsFromApi, generateChartSerie, getDatesFromPeriod, getDisabledSeries } from '../utils'
import TableView from '@/components/TableView'
import LineChart from '@/components/charts/SVGCharts/LineChart'
import { formatLabels } from '@/components/charts/SVGCharts/utils'
import { format, differenceInWeeks } from 'date-fns'
import LineAndBarChart from '@/components/charts/SVGCharts/LineAndBarChart'
import { europeNum } from '@/utils/general'
import ModalAssetsLogs from '@/components/ModalAssetsLogs'
import Components from '../Components'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    paddingBottom: theme.spacing(2)
  },
  section: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%'
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  topMargin: {
    marginTop: (theme.spacing(2) - 4)
  },
  smallViewport: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%'
  },
  glassBackground: {
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'saturate(120%) blur(16px)',
      backdropFilter: 'saturate(190%) blur(16px)',
      backgroundColor: 'rgba(16, 26, 38, 0.05)'
    }
  }
}))

const Production = ({ hasGlass = false, period = null, shared = false, guestToken = null, plantId: sharedPlantId = null, className, ...rest }) => {
  const classes = useStyles()
  const { user, currentProject } = useAuth()

  // Variabile che rappresenta il nome dell'impianto
  const { uuid: plantUuid, name: plantName, plantType } = useSelector(state => state.hydroPlantView)
  // Variabile di stato che rappresenta lo stato di caricamento dei dati
  const [isLoading, setIsLoading] = useState(false)
  // periodo selezionato tramite le chips
  const [customPeriod, setCustomPeriod] = useState('live')
  // data/intervallo di tempo selezionato tramite il date picker
  // const [selectedDate, setSelectedDate] = useState(moment())
  const [selectedDate, setSelectedDate] = useState(null)
  // periodo impostato dallo zoom sul grafico
  const [zoomPeriod, setZoomPeriod] = useState('live')
  // insieme delle serie da graficare
  const [chartData, setChartData] = useState({})
  // dati delle cards
  const [cardsData, setCardsData] = useState([])
  // assets dell'impianto
  const [plantAssets, setPlantAssets] = useState([])
  // devices dell'impianto
  const [plantDevices, setPlantDevices] = useState([])
  // Boolean per la visualizzazione della modal
  const [selectedAsset, setSelectedAsset] = useState(null)

  const plantId = sharedPlantId || plantUuid

  // Ref per calcolare l'altezza dei grafici
  const [rect, ref] = useClientRect()

  useEffect(() => {
    if (period) {
      setCustomPeriod(period)
    }
  }, [period])

  // Funzione per fare la GET dei dati
  const getData = useCallback(async (timeFrom, timeTo) => {
    const customHeaders = createGuestHeaders(guestToken)
    if (plantId) {
      const { data: response, error } = await api.get({
        savedUrl: 'plantDetails',
        fullResponse: true,
        path: `/${plantId}/production?timeFrom=${timeFrom}&timeTo=${timeTo}`,
        customHeaders
      })

      if (error) {
        return null
      }

      const { data: assets, error: assetsError } = await api.get({
        savedUrl: `https://api.apio.network/projects/${currentProject?.uuid}/assets`,
        params: { filter: { plantId } }
      })

      if (assetsError) {
        return null
      }

      const { data: devices, error: devicesError } = await api.get({
        savedUrl: `https://api.apio.network/projects/${currentProject?.uuid}/devices`,
        params: { filter: { plantId } }
      })

      if (devicesError) {
        return null
      }

      const dataObj = response.data
      return {
        dataObj,
        assets,
        devices
      }
    }
  }, [plantId, guestToken])

  const params = [{ name: 'plantId', value: plantId }, { name: 'plantName', value: plantName }, { name: 'plantType', value: plantType }]

  useEffect(() => {
    // Funzione che fa l'inizializzazione della view, settando i dati che i grafici consumeranno
    async function initialiseView (timeFrom, timeTo) {
      setIsLoading(true)
      const { dataObj, assets, devices } = await getData(timeFrom, timeTo)
      if (dataObj) {
        const { activePower, payload, performance, saltoMonteGriglia, volumeData, producedEnergyData, activePowerData, payloadData, saltoMonteGrigliaData, performanceData } = dataObj

        // Calcolo il volume cumulato e l'energia prodotta cumulata che andranno aggiunte al grafico
        const cumulatedVolume = calculateCumulatedSerie(volumeData)
        const cumulatedProducedEnergy = calculateCumulatedSerie(producedEnergyData)

        setCardsData(decodeCardsFromApi({
          activePower,
          payload,
          performance,
          saltoMonteGriglia
        }))

        if (differenceInWeeks(new Date(timeTo), new Date(timeFrom)) < 1) {
          setChartData({
            payloadData,
            activePowerData,
            saltoMonteGrigliaData,
            performanceData
          })
        } else {
          setChartData({
            volumeData,
            producedEnergyData,
            saltoMonteGrigliaData,
            performanceData,
            cumulatedProducedEnergy,
            cumulatedVolume
          })
        }
      }

      if (assets) {
        setPlantAssets(assets)
      }

      if (devices) {
        setPlantDevices(devices)
      }
      setIsLoading(false)
    }
    const periodDate = (selectedDate && selectedDate.toDate()) || new Date()
    // Prendo le date minima e massima da passare alle api
    const { minDate, maxDate } = getDatesFromPeriod(customPeriod, periodDate)
    if (!shared) {
      if (customPeriod === 'live') {
        if (zoomPeriod === 'live') {
          // Prima GET
          initialiseView(minDate, maxDate)
          // Attivazione del polling
          const pollingInterval = setInterval(() => {
            initialiseView(minDate, maxDate)
          }, 60000)
          return () => {
            clearInterval(pollingInterval)
          }
        }
      } else {
        if (customPeriod === zoomPeriod) {
          initialiseView(minDate, maxDate)
        }
      }
    } else {
      initialiseView(minDate, maxDate)
    }
  }, [customPeriod, plantId, getData, selectedDate, zoomPeriod, shared])

  const isExtraSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const isLarge = useMediaQuery(theme => theme.breakpoints.down('lg'))

  const graphHeight = isLarge ? rect?.height - 270 : rect ? rect.height - 250 : '100%'
  const graphWidth = rect?.width

  const periodFormatting = (period = 'live') => {
    if (period === 'week' || period === 'month') {
      return 'dd/MM/yy'
    } else if (period === 'year') {
      return 'MMMM'
    }

    return 'HH:mm'
  }

  const chartLabels = formatLabels(chartData?.performanceData?.map(el => el.label) || [], (label) => format(new Date(label), periodFormatting(customPeriod)))

  const seriesChartConfig = {
    volumeData: {
      name: 'Volume',
      color: 'blue',
      type: 'bar',
      uom: `m${String.fromCodePoint(0x00B3)}`,
      format: (value) => europeNum(value, 2)
    },
    saltoMonteGrigliaData: {
      name: 'Salto',
      color: 'red',
      uom: 'm',
      format: (value) => europeNum(value, 2)
    },
    performanceData: {
      name: 'Rendimento',
      color: 'yellow',
      uom: '%',
      format: (value) => europeNum(value, 2)
    },
    payloadData: {
      name: 'Portata',
      color: 'blue',
      uom: `m${String.fromCodePoint(0x00B3)}/s`,
      format: (value) => europeNum(value, 2)
    },
    producedEnergyData: {
      name: 'Energia Prodotta',
      color: 'orange',
      type: 'bar',
      uom: 'kWh',
      format: (value) => europeNum(value, 2)
    },
    activePowerData: {
      name: 'Potenza',
      color: 'orange',
      uom: 'kW',
      format: (value) => europeNum(value, 2)
    },
    cumulatedProducedEnergy: {
      name: 'Energia Prodotta Cumulata',
      color: '#FF5733',
      uom: 'kWh',
      initialVisibility: false,
      format: (value) => europeNum(value, 2)
    },
    cumulatedVolume: {
      name: 'Volume Cumulato',
      initialVisibility: false,
      color: 'lightblue',
      uom: `m${String.fromCodePoint(0x00B3)}`,
      format: (value) => europeNum(value, 2)
    }
  }

  const tableHeader = [
    { value: 'name', label: 'Sensore di livello' },
    { value: 'state.quotalibera', label: 'Quota s.l.m. pelo libero (m)', formatter: (num) => europeNum(num) },
    { value: 'state.quotaslm', label: 'Quota s.l.m. (m)', formatter: (num) => europeNum(num) },
    { value: 'state.sensor', label: 'Perc. Sensore (%)', formatter: (num) => europeNum(num) },
    { value: 'state.distmisurata', label: 'Dist. misurata dallo strumento (mm)', formatter: (num) => europeNum(num) },
    { value: 'propertyMappings.adc.resourceId', label: 'Dispositivo associato', formatter: (resourceId) => plantDevices?.find(device => device.uuid === resourceId)?.name ?? '-' },
    { value: 'stateUpdatedAt.adc', label: 'Last mex.', formatter: (isoDate) => format(new Date(isoDate), 'dd/MM/yy HH:mm') }
  ]

  const tableRowAction = (data) => {
    if (data) {
      setSelectedAsset(data)
    }
  }

  const roundNumberFormatter = (value) => {
    // Converto il valore da string a number
    const numberValue = Number(value?.replaceAll('.', ''))

    // Se il numero è maggiore di 1M, divido per 1M e ritorno numberM
    if (numberValue > 1000000) {
      return `${europeNum(numberValue / 1000000, 0)}M`
      // Altrimenti se è maggiore di 1K, divido per 1k e ritorno numberK
    } else if (numberValue > 1000) {
      return `${europeNum(numberValue / 1000, 0)}K`
      // Atrimenti ritorno il numero
    } else {
      return value
    }
  }

  return (
    <div
      className={
        isLarge
          ? clsx(classes.root, className, classes.smallViewport)
          : clsx(classes.root, className)
      }
      {...rest}
    >
      {selectedAsset ? <ModalAssetsLogs selectedAsset={selectedAsset} devices={plantDevices} open={!!selectedAsset} onClose={() => setSelectedAsset(null)} /> : null}
      <Box mt={2}>
        <Grid width='100%' container spacing={1}>
          {shared
            ? null
            : (
              <Grid style={{ order: 1 }} item xs={12} sm={10} md={10} xl={3}>
                <PeriodSelection
                  isLoading={isLoading}
                  selectedDate={selectedDate}
                  setSelectedDate={(date) => {
                    setZoomPeriod(customPeriod)
                    setSelectedDate(date)
                  }}
                  customPeriod={customPeriod}
                  setCustomPeriod={(period) => {
                    setCustomPeriod(period)
                    setZoomPeriod(period)
                  }}
                />
              </Grid>)}
          <Grid style={{ order: isLarge ? 3 : 2 }} item xs={12} md={12} xl={shared ? 12 : 8}>
            <Box width='100%'>
              <Grid container spacing={1}>
                {
                  cardsData.length > 0
                    ? cardsData.map((card, index) => (
                      <Grid key={`card-${index}`} item xs={12} md={6} lg={3}>
                        <InfoCard elevation={hasGlass ? 0 : 1} className={hasGlass ? classes.glassBackground : null} icon={card.icon} values={card.values} />
                      </Grid>))
                    : (
                      <>
                        <Grid item xs={12} md={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                      </>)
                }
              </Grid>
            </Box>
          </Grid>
          {shared
            ? null
            : !isExtraSmall && (
              <Grid style={{ order: isLarge ? 2 : 3 }} item xs={2} sm={2} md={2} xl={1}>
                <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='flex-end'>
                  <Box mr={1}>
                    <DownloadExcelButton
                      selectedDate={selectedDate}
                      period={customPeriod}
                      plantId={plantId}
                      plantName={plantName}
                      exportType='production'
                      description='Scarica i dati di produzione del periodo che stai visualizzando in un file Excel'
                    />
                  </Box>
                  <ShareButton userEmail={user ? user.email : null} userId={user ? user.uuid : null} path='production' params={params} period={customPeriod} />
                </Box>
              </Grid>)}
        </Grid>
      </Box>
      <Box ref={ref} width='100%' height='90%' mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card elevation={hasGlass ? 0 : 1} className={hasGlass ? clsx(classes.section, classes.glassBackground) : classes.section}>
              {isLoading
                ? (
                  <Box mt={-2} ml={-2}>
                    <LinearProgress color='primary' />
                  </Box>)
                : null}
              {shared
                ? <Typography className={isLoading ? clsx(classes.topMargin, classes.sectionTitle) : classes.sectionTitle} variant='h6'>Volume ed Energia Prodotta</Typography>
                : null}
              <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
                {customPeriod === 'live'
                  ? (<LineChart
                      showHours
                      width={graphWidth}
                      height={graphHeight * 0.6}
                      data={{
                        series: Object.keys(chartData).map(key => generateChartSerie(chartData[key], seriesChartConfig[key])),
                        labels: chartLabels
                      }}
                     />)
                  : (<LineAndBarChart
                      width={graphWidth}
                      height={graphHeight * 0.6}
                      initialHiddenSeries={getDisabledSeries(seriesChartConfig)}
                      data={{
                        series: Object.keys(chartData).map(key => generateChartSerie(chartData[key], seriesChartConfig[key], (el) => roundNumberFormatter(el))),
                        labels: chartLabels
                      }}
                     />)}

              </Box>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={hasGlass ? 0 : 1} className={hasGlass ? clsx(classes.section, classes.glassBackground) : classes.section}>
              <TableView header={tableHeader} data={plantAssets} rowAction={tableRowAction} />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.glassBackground} style={{ marginBottom: 24 }}>
              <Components />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default memo(Production)
