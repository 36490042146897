import SwatchColorPicker from '@/components/SwatchColorPicker'
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  root: {},
  closeButton: {
    color: theme.palette.grey[500]
  },
  stepDescription: {
    color: theme.palette.text.secondary
  }
}))

const EditChartDialog = ({ open, onClose, className, onConfirm, property }) => {
  const classes = useStyles()
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const currentPropertySettings = { color: property?.color ?? '#ffffff', graphType: property?.graphType ?? 'line' }

  const [currentSettings, setCurrentSettings] = useState(currentPropertySettings)

  const changeGraphType = (type) => {
    setCurrentSettings(prevSettings => ({ ...prevSettings, graphType: type }))
  }

  const changeColor = (color) => {
    setCurrentSettings(prevSettings => ({ ...prevSettings, color }))
  }

  return (
    <Dialog
      fullWidth
      fullScreen={isSmall}
      maxWidth='sm'
      open={open}
      onClose={onClose}
      className={clsx(classes.root, className)}
    >
      <DialogTitle>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography component='div' variant='h3'>
            Modifica il grafico
          </Typography>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box my={2}>
          <Typography variant='body2' className={classes.stepDescription}>
            Modifica colore o tipologia di grafico per l&apos;elemento
          </Typography>
          <Typography variant='body2' style={{ fontWeight: 'bold' }}>
            {property?.displayName ?? ''}
          </Typography>
        </Box>
        <Box display='flex' alignItems='center' my={4} style={{ gap: '32px' }}>
          <Box>
            <Typography variant='h6' style={{ marginBottom: 4 }}>Tipologia di grafico</Typography>
            <ButtonGroup size='small'>
              <Button onClick={() => changeGraphType('line')} variant='contained' color={currentSettings.graphType === 'line' ? 'primary' : 'default'}>Linea</Button>
              <Button onClick={() => changeGraphType('column')} variant='contained' color={currentSettings.graphType === 'column' ? 'primary' : 'default'}>Barra</Button>
            </ButtonGroup>
          </Box>
          <Box>
            <Typography variant='h6' style={{ marginBottom: 4 }}>Colore</Typography>
            <SwatchColorPicker color={currentSettings.color} onChange={changeColor} />
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} size='small'>Chiudi</Button>
        <Button onClick={() => onConfirm(currentSettings)} variant='contained' color='primary' size='small'>Salva</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditChartDialog
