import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { memo, useEffect, useRef, useState } from 'react'
import useCharts from '@/hooks/useCharts'
import LoadingCard from '../LoadingCard'
import { europeNum } from '@/utils/general'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}))

function TrendOverTimeGraph ({ name = null, data = {}, width = null, height = null, className, rawLabels: useRawLabels = false, ...rest }) {
  const classes = useStyles()
  // Prendo le istanze di core e am4Charts disponibili nel context
  const { chartsCore, amCharts, chartItLocale } = useCharts()
  // Creo una ref del grafico
  const chartRef = useRef(null)
  // Variabile che determina la prima renderizzazione a schermo
  const [firstPaint, setFirstPaint] = useState(true)

  // Funzione per formattare i valori numerici dei tooltips
  const formatTooltipValues = (uom, number) => {
    if (number === null) {
      return '-'
    }
    return europeNum(number, 2) ? `${europeNum(number, 2)} ${uom || ''}` : 0
  }

  // Funzione che dai dati del grafico restituisce il valore maggiore della serie
  const getMaxValue = (serie) => {
    const filteredValues = serie.data?.filter(el => el && el !== undefined) || [0]

    return Math.max(...filteredValues)
  }

  // Funzione che dai dati del grafico restituisce il valore maggiore della serie
  const getMinValue = (serie) => {
    const filteredValues = serie.data?.filter(el => el && el !== undefined) || [0]

    const hasNegativeValues = filteredValues.some(value => value < 0)

    if (!hasNegativeValues) {
      return 0
    }

    return Math.min(...filteredValues)
  }

  // Funzione che prende in ingresso il valore massimo tra quelli graficati e restituisce il valore aumentato di una quantità proporzionale al numero ricevuto in ingresso
  /* const addPaddingToValue = (maxValue = 0) => {
    const firstDigit = Number(`${maxValue}`.charAt(0) || 1)
    const valueNumberOfDigits = `${Math.floor(maxValue)}`.length
    return firstDigit === 0
      ? (maxValue + 0.3)
      : valueNumberOfDigits > 2
        ? maxValue + (firstDigit * 0.1)
        : maxValue + (firstDigit * 0.1)
  } */

  // Variabile che rappresenta l'id del div da selezionare come container del graph
  const graphContainerId = name ? `trend-over-time-graph-chart-${name}` : 'trend-over-time-graph-chart'

  // Funzione per la formattazione dei dati nel formato richiesto dalla libreria grafica
  const formatData = (dataObj) => {
    const { labels, series, rawLabels } = dataObj
    if (useRawLabels && rawLabels) {
      return rawLabels.map((label, index) => {
        const seriesElements = {}
        series.forEach((serie, serieIndex) => {
          seriesElements[`serie${serieIndex + 1}`] = serie.data.length > 0 ? serie.data[index] : 0
          seriesElements[`serie${serieIndex + 1}Formatted`] = formatTooltipValues(serie.uom, serie.data.length > 0 ? serie.data[index] : 0)
          seriesElements[`name${serieIndex + 1}`] = serie.name
        })
        return {
          date: label,
          tooltipCategory: moment(label).format('DD/MM/YYYY'),
          ...seriesElements
        }
      })
    } else if (labels) {
      return labels.map((label, index) => {
        const seriesElements = {}
        series.forEach((serie, serieIndex) => {
          seriesElements[`serie${serieIndex + 1}`] = serie.data.length > 0 ? serie.data[index] : 0
          seriesElements[`serie${serieIndex + 1}Formatted`] = formatTooltipValues(serie.uom, serie.data.length > 0 ? serie.data[index] : 0)
          seriesElements[`name${serieIndex + 1}`] = serie.name
        })
        return {
          category: `${label} (${index})`,
          tooltipCategory: label,
          ...seriesElements
        }
      })
    }

    return []
  }

  // Funzione che crea gli assi del grafico
  const createAxis = (chartInstance) => {
    // Asse delle date
    const dateAxis = useRawLabels ? chartInstance.xAxes.push(new amCharts.DateAxis()) : chartInstance.xAxes.push(new amCharts.CategoryAxis())
    dateAxis.tooltip.label.adapter.add('text', function (text) {
      if (text) {
        return text.replace(/ \(.*/, '')
      }
    })
    if (useRawLabels) {
      dateAxis.dataFields.date = 'date'
      dateAxis.renderer.minGridDistance = 60
    } else {
      dateAxis.dataFields.category = 'category'
      dateAxis.renderer.minGridDistance = 20
    }
    const dateAxisLabels = dateAxis.renderer.labels.template
    dateAxisLabels.fontWeight = 400
    dateAxisLabels.fontFamily = 'Roboto, sans-serif'
    dateAxisLabels.fontSize = 12
    dateAxisLabels.fill = '#ffffff'
    dateAxisLabels.maxWidth = 40
    dateAxisLabels.adapter.add('textOutput', function (text) {
      return text?.replace(/ \(.*/, '') || text
    })
    // Se lo schermo è piccolo, inclino le labels a 45 gradi
    dateAxis.events.on('sizechanged', function (ev) {
      const axis = ev.target
      const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex)
      if (cellWidth < axis.renderer.labels.template.maxWidth + 15) {
        axis.renderer.labels.template.rotation = -45
        axis.renderer.labels.template.horizontalCenter = 'right'
        axis.renderer.labels.template.verticalCenter = 'middle'
      } else {
        axis.renderer.labels.template.rotation = 0
        axis.renderer.labels.template.horizontalCenter = 'middle'
        axis.renderer.labels.template.verticalCenter = 'top'
      }
    })

    // Per ogni serie creo un asse
    const valueAxes = data.series.map((serie, serieIndex) => {
      const valueMax = getMaxValue(serie)
      const valueMin = getMinValue(serie)
      const serieAxis = chartInstance.yAxes.push(new amCharts.ValueAxis())
      serieAxis.min = valueMin
      // serieAxis.max = addPaddingToValue(valueMax)
      serieAxis.max = valueMax
      serieAxis.renderer.minGridDistance = 40
      serieAxis.strictMinMax = true
      serieAxis.renderer.grid.template.disabled = true
      serieAxis.title.text = `${serie.name} (${serie.uom})`
      serieAxis.title.fontSize = 12
      serieAxis.title.fontWeight = 500
      serieAxis.title.fontFamily = 'Roboto, sans-serif'
      serieAxis.title.fill = '#ffffff'
      serieAxis.renderer.labels.template.fontWeight = 400
      serieAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
      serieAxis.renderer.labels.template.fontSize = 12
      serieAxis.renderer.labels.template.fill = '#ffffff'
      serieAxis.renderer.opposite = serieIndex % 2 !== 0
      serieAxis.tooltip.disabled = true

      return serieAxis
    })

    return valueAxes
  }

  // Funzione che crea un tooltip unificato per tutte le serie da graficare
  const createTooltip = (chartInstance, currentSerie, serieIndex) => {
    // Dato che sto creando un unico tooltip unificato per tutte le serie da graficare
    if (serieIndex === 0) {
      // Abilito i tooltips
      currentSerie.tooltipText = ['[bold]serie[/] {valueY}']
      // definisco un adapter che compone il contenuto del tooltip
      currentSerie.adapter.add('tooltipText', function (ev) {
        let text = '[bold]{tooltipCategory}[/]\n\n'

        chartInstance.series.each(function (item) {
          if (!item.isHidden && serieIndex === 0) {
            text += '[' + item.stroke.hex + ']● [/]' + item.name + ': [bold]{' + item.dataFields.formattedValue + '}[/]\n'
          }
        })
        return text
      })
      // Definisco una grandezza per il tooltip e lo faccio andare a capo in caso di testi più lunghi
      currentSerie.tooltip.label.width = 320
      currentSerie.tooltip.label.wrap = true
      // Disabilito il colore del tooltip in base al colore della serie e lo definisco in maniera programmatica
      currentSerie.tooltip.getFillFromObject = false
      currentSerie.tooltip.background.fill = new chartsCore.InterfaceColorSet().getFor('alternativeBackground')
      currentSerie.tooltip.background.stroke = new chartsCore.InterfaceColorSet().getFor('alternativeBackground')
      currentSerie.tooltip.background.opacity = 0.8
      // Definisco gli stili per il testo all'interno del tooltip
      currentSerie.tooltip.label.fill = chartsCore.color('#ffffff')
      currentSerie.tooltip.label.fontFamily = 'Roboto, sans-serif'
      currentSerie.tooltip.label.fontSize = 12
    }
  }

  // Funzione che crea una series di un grafico a barre
  const createColumnSeries = (chartInstance, dataSet, serieIndex, axis) => {
    const colorSet = new chartsCore.ColorSet()
    const serie = chartInstance.series.push(new amCharts.ColumnSeries())
    if (!firstPaint) {
      serie.showOnInit = false
    }
    if (useRawLabels) {
      serie.dataFields.dateX = 'date'
    } else {
      serie.dataFields.categoryX = 'category'
    }
    serie.fill = chartsCore.color(dataSet.color ?? colorSet.next())
    serie.stroke = chartsCore.color(dataSet.color ?? colorSet.next())
    serie.legendSettings.fill = chartsCore.color(dataSet.color ?? colorSet.next())
    serie.fillOpacity = 0.9
    serie.name = dataSet.name
    serie.dataFields.valueY = `serie${serieIndex + 1}`
    serie.dataFields.formattedValue = `serie${serieIndex + 1}Formatted`
    // Disabilito il colore del tooltip in base al colore della serie e lo definisco in maniera programmatica
    serie.tooltip.getFillFromObject = false
    serie.tooltip.getStrokeFromObject = false
    serie.yAxis = axis
    serie.tooltip.background.fill = chartsCore.color('#FFF')
    serie.tooltip.background.strokeWidth = 3
    serie.strokeWidth = 2

    // Creo il tooltip da mostrare
    createTooltip(chartInstance, serie, serieIndex)
  }

  // Funzione che crea una series di un grafico a linea
  const createLineSeries = (chartInstance, dataSet, serieIndex, axis) => {
    const colorSet = new chartsCore.ColorSet()
    const serie = chartInstance.series.push(new amCharts.LineSeries())
    if (!firstPaint) {
      serie.showOnInit = false
    }

    if (useRawLabels) {
      serie.dataFields.dateX = 'date'
    } else {
      serie.dataFields.categoryX = 'category'
    }
    serie.stroke = chartsCore.color(dataSet.color ?? colorSet.next())
    serie.fill = chartsCore.color(dataSet.color ?? colorSet.next())
    serie.legendSettings.stroke = chartsCore.color(dataSet.color ?? colorSet.next())
    serie.name = dataSet.name
    serie.dataFields.valueY = `serie${serieIndex + 1}`
    serie.dataFields.formattedValue = `serie${serieIndex + 1}Formatted`
    // Disabilito il colore del tooltip in base al colore della serie e lo definisco in maniera programmatica
    serie.tooltip.getFillFromObject = false
    serie.tooltip.getStrokeFromObject = false
    serie.yAxis = axis
    serie.tooltip.background.fill = chartsCore.color('#FFF')
    serie.tooltip.background.strokeWidth = 3
    serie.strokeWidth = 3

    // Creo il tooltip da mostrare
    createTooltip(chartInstance, serie, serieIndex)
  }

  // Funzione in cui definisco tutte le serie da graficare
  const createSeries = (chartInstance, valueAxes) => {
    // Creo una serie per ogni elemento dell'array series
    data.series.forEach((serie, serieIndex) => {
      // Verifico il tipo di serie che voglio creare, il tipo può essere 'line' o 'column'
      if (serie.type === 'line') {
        createLineSeries(chartInstance, serie, serieIndex, valueAxes[serieIndex])
      } else if (serie.type === 'column') {
        createColumnSeries(chartInstance, serie, serieIndex, valueAxes[serieIndex])
      }
    })
  }

  // Funzione che crea la legenda del grafico
  const createLegend = (chartInstance) => {
    // Istanzio un nuovo oggetto Legend
    chartInstance.legend = new amCharts.Legend()
    // Uso il marker di base
    chartInstance.legend.useDefaultMarker = true
    // Definisco la larghezza massima el posizione della legenda
    chartInstance.legend.position = 'bottom'
    chartInstance.legend.itemContainers.template.marginTop = -5
    // definisco gli stili delle labels della legenda
    chartInstance.legend.labels.template.fill = chartsCore.color('#ffffff')
    chartInstance.legend.labels.template.fontFamily = 'Roboto, sans-serif'
    chartInstance.legend.labels.template.fontSize = 12
    // Modifico il marker di default con delle modifiche custom
    const markerTemplate = chartInstance.legend.markers.template
    const marker = markerTemplate.children.getIndex(0)
    markerTemplate.width = 12
    markerTemplate.height = 12
    marker.cornerRadius(12, 12, 12, 12)
  }

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      // Prendo il div con l'id indicato per creare il grafico
      const chart = chartsCore.create(graphContainerId, amCharts.XYChart)
      chart.language.locale = chartItLocale
      // Se non è la prima renderizzazione, disabilito le animazioni
      if (!firstPaint) {
        chart.showOnInit = false
      }
      // Assegno i dati al grafico dopo averli formattati
      chart.data = formatData(data)
      // Aggiungo il cursore
      chart.cursor = new amCharts.XYCursor()
      // Disabilito il bottone di zoom out
      chart.zoomOutButton.disabled = false
      // Aggiungo la legenda
      createLegend(chart)
      // Creo gli assi del grafico
      const valueAxes = createAxis(chart)
      // Creo la serie da graficare
      createSeries(chart, valueAxes)
      // Assegno il grafico alla ref
      chartRef.current = chart
      // Dopo che è stato renderizzato la prima volta, setto la variabile a false
      if (firstPaint) {
        setFirstPaint(false)
      }
      return () => {
        chartRef.current.dispose()
      }
    }
  }, [data])
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {Object.keys(data).length > 0
        ? (
          <div
            id={graphContainerId}
            style={{
              width: width || '100%',
              height: height || 300
            }}
          />)
        : <LoadingCard />}
    </div>
  )
}
export default memo(TrendOverTimeGraph)
