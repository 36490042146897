import { createSlice } from '@reduxjs/toolkit'
import { decodeFromApi, encodeForApi } from '@/utils/adapters/hydro/plantAdapter'
import api from '@micmnt/apis'
import log from '@pelv/frontlog'
import { deepEqual } from '@/utils/objDeepComparison'
import { basePlanimetry } from './basePlanimetry'

const initialState = {
  dataloggers: [],
  loadData: false,
  addedDataloggers: [],
  assets: [],
  assetTypes: [],
  devices: [],
  maintenance: false,
  activeStep: 0,
  hasAutosave: false,
  plantType: 'hydro',
  displayOverview: 'planimetry',
  selectedDatalogger: '',
  documents: [],
  anagraphic: {
    peakPower: '',
    plantCode: '',
    contractType: '',
    referentName: '',
    referentClient: '',
    referentRole: '',
    referentEmail: '',
    referentPhone: '',
    monitoringName: '',
    monitoringEmail: '',
    monitoringPhone: '',
    operationAndMaintenaceName: '',
    operationAndMaintenacePhone: '',
    operationAndMaintenaceEmail: ''
  },
  name: '',
  peakPower: '',
  startDate: null,
  contractDuration: 1,
  endDate: '',
  address: '',
  mapConfig: { zoom: 20, center: { lat: 0, lng: 0 } },
  planimetry: basePlanimetry,
  // planimetry: { draw: [], config: [] },
  logoURL: null
}

const slice = createSlice({
  name: 'hydroPlantCreation',
  initialState,
  reducers: {
    setLoadData (state, actions) {
      const { loading } = actions.payload
      state.loadData = loading
    },
    setCurrentPlant (state, actions) {
      const { plant } = actions.payload
      const { displayOverview, mapConfig, hasAutosave, logoURL, name, uuid, anagraphic, devices, assets, assetTypes, addedDataloggers, address, contractDuration, endDate, startDate, maintenance, peakPower, planimetry, plantType, documents, ...rest } = plant
      const { displayOverview: initDisplayOverview, mapConfig: initMapConfig, hasAutosave: initHasAutosave, documents: initDocuments, anagraphic: initAnagraphic } = initialState

      // L'impianto da modificare potrebbe non avere una proprietà displayOverview (è stato creato precedentemente all'inserimento di questa sezione)
      // se la proprietà è presente la setto nell'impianto corrente
      if (displayOverview) {
        state.displayOverview = displayOverview
      } else {
        state.displayOverview = initDisplayOverview
      }

      // L'impianto da modificare potrebbe non avere un oggetto mapConfig (è stato creato precedentemente all'inserimento di questa sezione)
      // se l'oggetto è presente lo setto nell'impianto corrente
      if (mapConfig) {
        state.mapConfig = mapConfig
      } else {
        state.mapConfig = initMapConfig
      }

      // l'impianto da modificare potrebbe non avere un oggetto anagrafica (è stato creato precedentemente all'inserimento di questa sezione)
      // se l'oggetto è presente lo setto nel'impianto corrente
      if (anagraphic) {
        state.anagraphic = anagraphic
        // altrimenti setto il valore di default dell'oggetto anagraphic che si utilizza per un nuovo impianto
      } else {
        state.anagraphic = initAnagraphic
      }

      // Se ci sono documenti salvati li setto altrimenti imposto l'array vuoto
      if (documents) {
        state.documents = documents
      } else {
        state.documents = initDocuments
      }
      // Se c'è il logo salvato lo setto altrimenti imposto l'oggetto a null
      if (logoURL) {
        state.logoURL = logoURL
      } else {
        state.logoURL = null
      }

      // Se c'è l'autosave attivato, lo setto
      if (hasAutosave) {
        state.hasAutosave = hasAutosave
      } else {
        state.hasAutosave = initHasAutosave
      }

      state.name = name
      state.uuid = uuid
      state.addedDataloggers = addedDataloggers
      state.devices = devices
      state.assets = assets
      state.assetTypes = assetTypes
      state.address = address
      state.contractDuration = contractDuration
      state.endDate = endDate || ''
      state.startDate = startDate || null
      state.maintenance = maintenance
      state.peakPower = peakPower
      state.planimetry = planimetry
      state.plantType = plantType
      state.loadData = false
      state.rest = rest
    },
    setAddress (state, action) {
      const { newAddress } = action.payload
      state.address = newAddress
    },
    setPlantType (state, action) {
      const { plantType } = action.payload
      state.plantType = plantType
    },
    setAutosave (state, action) {
      const { hasAutosave } = action.payload
      state.hasAutosave = hasAutosave
    },
    setLogo (state, action) {
      const { url } = action.payload
      if (state.uuid) {
        api.post({ savedUrl: 'forceSummaryRefresh', path: `/${state.uuid}/summary` })
      }
      state.logoURL = url
    },
    resetPlant (state) {
      const { anagraphic, hasAutosave, dataloggers, addedDataloggers, assetTypes, assets, maintenance, activeStep, plantType, selectedDatalogger, name, peakPower, startDate, contractDuration, endDate, address, planimetry, documents } = initialState
      if (state.uuid) {
        delete state.uuid
      }
      if (state.devices) {
        delete state.devices
      }
      state.dataloggers = dataloggers
      state.anagraphic = anagraphic
      state.addedDataloggers = addedDataloggers
      state.assets = assets
      state.assetTypes = assetTypes
      state.maintenance = maintenance
      state.activeStep = activeStep
      state.hasAutosave = hasAutosave
      state.plantType = plantType
      state.contractDuration = contractDuration
      state.selectedDatalogger = selectedDatalogger
      state.peakPower = peakPower
      state.startDate = startDate
      state.endDate = endDate
      state.documents = documents
      state.name = name
      state.logoURL = null
      state.address = address
      state.planimetry = planimetry
      state.displayOverview = 'planimetry'
    },
    changeAnagraphicValues (state, action) {
      const { value, name } = action.payload
      if (state.anagraphic !== undefined) {
        if (state.anagraphic[name] !== undefined) {
          state.anagraphic[name] = value
        }
      }
    },
    changeValues (state, action) {
      const { value, name } = action.payload
      if (state[name] !== undefined) {
        state[name] = value
      }
    },
    setDeviceTypeDocuments (state, action) {
      const { documents, deviceTypeId } = action.payload
      // Creo una copia dell'array dei datalogger aggiunti all'impianto
      const addedDataloggers = JSON.parse(JSON.stringify(state.addedDataloggers))
      const updatedDataloggers = addedDataloggers.map(datalogger => {
        if (datalogger.includes && datalogger.includes.devices) {
          const devices = [...datalogger.includes.devices]
          const newDevices = devices.map(device => {
            const deviceType = device.deviceType
            if (deviceType && deviceType.uuid === deviceTypeId) {
              const newDeviceType = {
                ...deviceType,
                metadata: {
                  ...deviceType.metadata,
                  documents
                }
              }

              return {
                ...device,
                deviceType: newDeviceType
              }
            }

            return device
          })

          return {
            ...datalogger,
            includes: {
              ...datalogger.includes,
              devices: newDevices
            }
          }
        }

        return datalogger
      })
      state.addedDataloggers = updatedDataloggers
    },
    setPlantDocuments (state, action) {
      const { documents } = action.payload
      state.documents = documents
    },
    setPlanimetry (state, action) {
      const { list } = action.payload
      state.planimetry = list
    },
    setStep (state, action) {
      const { step } = action.payload
      state.activeStep = step
    },
    setDataloggers (state, action) {
      const { dataloggers } = action.payload
      state.dataloggers = dataloggers
    },
    removeDatalogger (state, action) {
      const { dataloggerId } = action.payload
      // Datalogger disponibili
      const currentDataloggers = Object.assign([], state.dataloggers)
      // Datalogger assegnati all'impianto
      const addedDataloggers = Object.assign([], state.addedDataloggers)
      const currentDistribution = JSON.parse(JSON.stringify(state.distribution))
      const currentPlanimetryConfig = JSON.parse(JSON.stringify(state.planimetry.config))
      const selectedDataloggerIndex = addedDataloggers.findIndex(datalogger => datalogger.uuid === dataloggerId)
      const selectedDatalogger = addedDataloggers.splice(selectedDataloggerIndex, 1)
      if (selectedDatalogger) {
        // Rimetto l'oggetto datalogger solo se non è già presente nell'array dei datalogger disponibili
        const existingDatalogger = currentDataloggers.find(datalogger => datalogger.uuid === dataloggerId)
        if (!existingDatalogger) {
          currentDataloggers.push(selectedDatalogger[0])
        }

        // svuoto le distribuzioni create per i devices sottesi al datalogger eliminato
        const devices = selectedDatalogger[0].includes && selectedDatalogger[0].includes.devices

        devices.forEach(device => {
          if (currentDistribution[device.uuid] && currentDistribution[device.uuid] !== undefined) {
            delete currentDistribution[device.uuid]
          }

          currentPlanimetryConfig.forEach(configElement => {
            if (configElement.devices) {
              // rimuovo i devices dall'array devices di ogni elemento di configurazione
              const newDevices = configElement.devices.filter(el => el.deviceId !== device.uuid)
              // assegno il nuovo array di devices filtrato
              configElement.devices = newDevices
            }
          })
        })

        state.distribution = currentDistribution
        state.dataloggers = currentDataloggers
        state.addedDataloggers = addedDataloggers
        state.planimetry.config = currentPlanimetryConfig
      }
    },
    updateAddedDatalogger (state, action) {
      const { dataloggerId, newDevice } = action.payload
      // Creo una copia dell'array dei datalogger aggiunti all'impianto
      const addedDataloggers = JSON.parse(JSON.stringify(state.addedDataloggers))
      // Filtro tutti i datalogger che non sono quello selezionato
      const otherDataloggers = addedDataloggers.filter(el => el.uuid !== dataloggerId)
      // Prendo il datalogger selezionato
      const selectedDatalogger = addedDataloggers.find(el => el.uuid === dataloggerId)
      // console.log('datasheet - selectedDatalogger: ', selectedDatalogger)
      // Se il datalogger selezionato esiste
      if (selectedDatalogger && selectedDatalogger !== undefined) {
        // Se il datalogger ha l'array di device
        if (selectedDatalogger.includes && selectedDatalogger.includes.devices) {
          // Prendo tutti i devices di questo datalogger
          const dataloggerDevices = JSON.parse(JSON.stringify(selectedDatalogger.includes.devices))
          const newDataloggerDevices = dataloggerDevices.map(el => {
            if (el.uuid === newDevice.uuid) {
              el.metadata.documents = newDevice.metadata.documents
            }

            return el
          })
          selectedDatalogger.includes.devices = newDataloggerDevices
          otherDataloggers.push(selectedDatalogger)
          // console.log('datasheet - otherDataloggers: ', otherDataloggers)
          state.addedDataloggers = otherDataloggers
        }
      }
    },
    selectDatalogger (state, action) {
      const { dataloggerId } = action.payload
      const currentDataloggers = Object.assign([], state.dataloggers)
      const addedDataloggers = Object.assign([], state.addedDataloggers)
      const selectedDataloggerIndex = currentDataloggers.findIndex(datalogger => datalogger.uuid === dataloggerId)
      const selectedDatalogger = currentDataloggers.splice(selectedDataloggerIndex, 1)
      if (selectedDatalogger) {
        addedDataloggers.push(selectedDatalogger[0])
        state.dataloggers = currentDataloggers
        state.addedDataloggers = addedDataloggers
        // console.log('PlantComponent - devices on addedDataloggers: ', state.addedDataloggers)
      }
    },
    updateMapConfig (state, action) {
      const { center, zoom } = action.payload
      state.mapConfig = {
        zoom,
        center
      }
    }
  }
})

export const reducer = slice.reducer

export const updateAddedDatalogger = (dataloggerId, newDevice) => dispatch => {
  dispatch(slice.actions.updateAddedDatalogger({ dataloggerId, newDevice }))
}

export const setStep = (step) => dispatch => {
  dispatch(slice.actions.setStep({ step }))
}

export const savePlanimetry = (list) => dispatch => {
  dispatch(slice.actions.setPlanimetry({ list }))
}

export const updatePlantDocuments = (documents) => dispatch => {
  dispatch(slice.actions.setPlantDocuments({ documents }))
}

export const updateDeviceTypeDocuments = (documents, deviceTypeId) => dispatch => {
  dispatch(slice.actions.setDeviceTypeDocuments({ documents, deviceTypeId }))
}

export const addDatalogger = (dataloggerId) => dispatch => {
  dispatch(slice.actions.selectDatalogger({ dataloggerId }))
}

export const deleteDatalogger = (dataloggerId) => dispatch => {
  dispatch(slice.actions.removeDatalogger({ dataloggerId }))
}

export const getDataloggers = ({ skip = 0, limit = 50, filter = null, hasNoPlant = null }) => async dispatch => {
  const params = {
    skip,
    limit,
    sortorder: 'DESC',
    include: 'devices'
  }

  if (filter) {
    params.filter = filter
  }

  if (hasNoPlant !== null) {
    params.hasNoPlant = hasNoPlant
  }

  const { data: dataloggers } = await api.get({ savedUrl: 'nodesList', params })
  if (dataloggers) {
    dispatch(slice.actions.setDataloggers({ dataloggers }))
  }
}

export const createPlant = (plant, reset = true, userIsSaving = false) => async dispatch => {
  const { uuid, assets, devices } = plant
  log({ text: 'createPlant - plant => ', variable: plant, tag: 'pvPlantSlice' })

  const body = encodeForApi(plant)
  log({ text: 'encodeForApi - body => ', variable: JSON.stringify(body), tag: 'pvPlantSlice' })

  const { data: response, error } = await api.post({ savedUrl: 'dashboard', path: '/plants', body })
  if (error) {
    console.error('creation plant error: ', error)
    return { createdPlant: null, error: true }
  }

  const createdPlant = { ...response }

  if (createdPlant.uuid) {
    for (const asset of assets) {
      // Controllo se ha un uuid e se è stato modificato
      if (asset.uuid && asset.dirty) {
        // Comparo il valore delle additionalProperties con quello del device selezionato, se non è uguale faccio una PUT sul device
        const deviceId = asset?.propertyMappings?.adc?.resourceId ?? ''
        const currentDevice = devices.find(device => device.uuid === deviceId) ?? {}
        const deviceAdditionalPropertiesObj = currentDevice.metadata?.additionalProperties.find(obj => obj.sensorAdc === asset.additionalProperties.sensorAdc) ?? {}
        if (Object.keys(deviceAdditionalPropertiesObj).length > 0 && Object.keys(asset.additionalProperties).length > 0 && !deepEqual(deviceAdditionalPropertiesObj, asset.additionalProperties)) {
          const otherDeviceAdditionalProperties = currentDevice.metadata?.additionalProperties.filter(el => el.sensorAdc !== asset.additionalProperties.sensorAdc)
          const newAdditionalProperties = [...otherDeviceAdditionalProperties, { ...asset.additionalProperties }]
          const editableDevice = {
            ...currentDevice,
            metadata: {
              ...currentDevice.metadata,
              additionalProperties: newAdditionalProperties
            }
          }

          const { error: deviceError } = await api.put({
            savedUrl: `https://api.apio.network/projects/${createdPlant?.projectId}/devices/${deviceId}`,
            body: editableDevice
          })

          if (deviceError) {
            break
          }
        }
        delete asset.additionalProperties
        delete asset.dirty
        const { error: assetsError } = await api.put({
          savedUrl: `https://api.apio.network/projects/${createdPlant?.projectId}/assets/${asset.uuid}`,
          body: asset
        })

        if (assetsError) {
          break
        }
      } else if (asset.id && !asset.uuid) {
        const propertyMappingGenericKey = Object.keys(asset?.propertyMappings).find(key => key.includes('adc')) ?? ''
        const deviceId = asset?.propertyMappings?.[propertyMappingGenericKey]?.resourceId ?? ''
        const currentDevice = devices.find(device => device.uuid === deviceId) ?? {}
        const deviceAdditionalPropertiesObj = currentDevice.metadata?.additionalProperties.find(obj => obj.sensorAdc === asset.additionalProperties.sensorAdc) ?? {}
        if (Object.keys(deviceAdditionalPropertiesObj).length > 0 && Object.keys(asset.additionalProperties).length > 0 && !deepEqual(deviceAdditionalPropertiesObj, asset.additionalProperties)) {
          const otherDeviceAdditionalProperties = currentDevice.metadata?.additionalProperties.filter(el => el.sensorAdc !== asset.additionalProperties.sensorAdc)
          const newAdditionalProperties = [...otherDeviceAdditionalProperties, { ...asset.additionalProperties }]
          const editableDevice = {
            ...currentDevice,
            metadata: {
              ...currentDevice.metadata,
              additionalProperties: newAdditionalProperties
            }
          }
          const { error: deviceError } = await api.put({
            savedUrl: `https://api.apio.network/projects/${createdPlant?.projectId}/devices/${deviceId}`,
            body: editableDevice
          })

          if (deviceError) {
            break
          }
        }
        delete asset.additionalProperties
        delete asset.dirty
        const { error: assetsError } = await api.post({
          savedUrl: `https://api.apio.network/projects/${createdPlant?.projectId}/assets`,
          body: { ...asset, plantId: createdPlant.uuid }
        })

        if (assetsError) {
          break
        }
      }
    }
  }

  if (!uuid || userIsSaving) {
    const { data: summaryRefreshResponse } = await api.post({ savedUrl: 'forceSummaryRefresh', path: `/${createdPlant.uuid}/summary` })
    log({ text: 'summaryRefreshResponse => ', variable: summaryRefreshResponse, tag: 'pvPlantSlice' })
  }
  if (reset) {
    dispatch(slice.actions.resetPlant())
  }
  return { createdPlant, error: false }
}

export const resetInitState = () => dispatch => {
  dispatch(slice.actions.resetPlant())
}

export const getCurrentPlant = (plantId, projectId, firstLoad = false) => async dispatch => {
  if (plantId) {
    if (firstLoad) {
      dispatch(slice.actions.setLoadData({ loading: true }))
    }
    const options = {
      path: `/plants/${plantId}`
    }

    const { data: response } = await api.get({ savedUrl: 'dashboard', ...options })

    const { data: assetTypes } = await api.get({
      savedUrl: `https://api.apio.network/projects/${projectId}/assetTypes`
    })

    const { data: assets } = await api.get({
      savedUrl: `https://api.apio.network/projects/${projectId}/assets`,
      params: { filter: { plantId } }
    })

    const { plant, dataloggers, devices } = response || {}
    const adaptedPlant = decodeFromApi({ assets, assetTypes, plant, dataloggers, devices })
    dispatch(slice.actions.setCurrentPlant(adaptedPlant))
  }
}

export const changeContractValue = (value, name) => dispatch => {
  dispatch(slice.actions.changeValues({ value, name }))
}

export const changeAnagraphicValue = (value, name) => dispatch => {
  dispatch(slice.actions.changeAnagraphicValues({ value, name }))
}

export const changePlantType = (plantType) => dispatch => {
  dispatch(slice.actions.setPlantType({ plantType }))
}

export const setAddress = (newAddress) => dispatch => {
  dispatch(slice.actions.setAddress({ newAddress }))
}

export const updateLogo = (url = null) => dispatch => {
  dispatch(slice.actions.setLogo({ url }))
}

export const togglePlantAutosave = (hasAutosave) => dispatch => {
  dispatch(slice.actions.setAutosave({ hasAutosave }))
}

// Funzione che setta la configurazione della mappa della planimetria
export const updateMapConfig = ({ center, zoom }) => dispatch => {
  dispatch(slice.actions.updateMapConfig({ center, zoom }))
}
