import log from '@pelv/frontlog'
import ContentTooltip from '../shared/ContentTooltip'
import InfoboxContent from '../shared/InfoboxContent'
import MeterContent from '../shared/MeterContent'
import ObstacleContent from '../shared/ObstacleContent'
import TextContent from '../shared/TextContent'
import GeneratorContent from './GeneratorContent'
import InverterContent from './InverterContent'
import SolarimeterContent from './SolarimeterContent'
import WeatherStationContent from './WeatherStationContent'
import WindRoseContent from './WindRoseContent'

export const libraryObjects = {
  generator: {
    img: '/static/images/panel.svg',
    name: 'Generatore',
    notes: 'Generatore',
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: [
        {
          type: 'rect',
          width: 40,
          height: 60,
          fill: 'blue',
          stroke: 'blue',
          originX: 'left',
          originY: 'top'
        }
      ]
    },
    modal: (props) => <GeneratorContent {...props} />,
    // onReceive: (canvasObj, currentValues) => (objModified),
    onReceive: () => { },
    event: {
      onLoad: (thisDraw, config) => {
        // thisDraw.objects = []
        const thisDrawCopy = JSON.parse(JSON.stringify(thisDraw))
        // thisDrawCopy.objects = []
        log({ text: 'thisDrawCopy - pre => ', variable: thisDrawCopy, tag: 'planimetry' })
        const col = 5
        const row = 10
        let thisRow = 0
        let thisCol = 0

        const protoCell = {
          type: 'rect',
          width: 8,
          height: 6,
          fill: 'rgba(0,0,0,0)',
          stroke: 'white',
          originX: 'left',
          originY: 'top',
          left: 0,
          top: 0
        }
        for (let s = 0; s < (col * row); s++) {
          const thisCell = JSON.parse(JSON.stringify(protoCell))
          thisCell.left = thisCol * protoCell.width
          thisCell.top = thisRow * protoCell.height

          thisDrawCopy.objects.push(thisCell)
          // thisRow = parseInt(s / thisCol)

          log([
            { text: 'generator - thisCol => ', variable: thisCol, tag: 'planimetry' },
            { text: 'generator - col => ', variable: col, tag: 'planimetry' },
            { text: 'generator - thisRow => ', variable: thisRow, tag: 'planimetry' },
            { text: 'generator - row => ', variable: row, tag: 'planimetry' },
            { text: 'generator - s => ', variable: s, tag: 'planimetry' }
          ])

          if (thisCol > 3) {
            thisCol = 0
            thisRow++
          } else {
            thisCol++
          }
        }
        // console.log('onLoad: ', thisDraw)
        // console.log('config: ', config)
        if (config.data && config.data.draw && config.data.draw.backgroundColor) {
          thisDrawCopy.fill = config.data.draw.backgroundColor
        }

        log({ text: 'thisDrawCopy - post => ', variable: thisDrawCopy, tag: 'planimetry' })
        return thisDrawCopy
      },
      mouseDown: () => {
        console.log('onMouseDown')
      },
      mouseOver: () => {
        console.log('onMouseOver')
      }
    }
  },
  inverter: {
    img: '/static/images/inverter.svg',
    name: 'Inverter',
    notes: 'Oggetto che rappresenta un Inverter',
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: [
        {
          type: 'circle',
          radius: 30,
          fill: '#fff',
          stroke: '#000',
          originX: 'center',
          originY: 'center'
        },
        {
          type: 'text',
          text: 'I',
          left: 0,
          top: 0,
          originX: 'center',
          originY: 'center'
        }
      ]
    },
    modal: (props) => <InverterContent {...props} />,
    tooltip: (props) => <ContentTooltip {...props} />,
    onReceive: (canvasObj, config) => {
      // 1. se non esiste ritorno null

      const newObj = canvasObj
      let generalStatus = ''

      for (let s = 0; s < config.devices.length; s++) {
        if (
          config.devices[s].properties &&
          config.devices[s].properties.status &&
          config.devices[s].properties.status.value &&
          config.devices[s].properties.status.value.code
        ) {
          generalStatus = config.devices[s].properties.status.value.code
        }
      }

      if (newObj.objects && newObj.objects.length > 0) {
        if (generalStatus === 'ok') {
          // newObj.objects[0].fill = 'white'
          newObj.objects[0].fill = (config.data && config.data.draw && config.data.draw.backgroundColor) || 'white'
        } else if (generalStatus === 'anomaly_comunication' || generalStatus === 'maintenance') {
          newObj.objects[0].fill = 'yellow'
        } else if (generalStatus === 'anomaly_energy') {
          newObj.objects[0].fill = 'orange'
        } else if (generalStatus === 'anomaly_component') {
          newObj.objects[0].fill = 'red'
        }
      }

      return newObj
    },
    event: {
      mouseDown: () => {
        console.log('onMouseDown')
      },
      mouseOver: (config) => {
        const infos = []
        console.log('onMouseOver: ', config)
        for (let s = 0; s < config.devices.length; s++) {
          for (const n in config.devices[s].properties) {
            if (n !== 'status') {
              const label = String(config.devices[s].properties[n].label)
              const value = String(config.devices[s].properties[n].value)
              infos.push({ label, value })
            }
          }
        }

        return infos
      }
    }
  },
  weather: {
    img: '/static/images/station.svg',
    name: 'Centralina Meteo',
    notes: "Centralina Meteo dell'impianto",
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: [
        {
          type: 'circle',
          radius: 30,
          fill: '#fff',
          stroke: '#000',
          originX: 'center',
          originY: 'center'
        },
        {
          type: 'text',
          text: 'C',
          left: 0,
          top: 0,
          originX: 'center',
          originY: 'center'
        }
      ]
    },
    modal: (props) => <WeatherStationContent {...props} />,
    onReceive: () => { },
    event: {
      mouseDown: () => {
        console.log('onMouseDown')
      },
      mouseOver: () => {
        console.log('onMouseOver')
      }
    }
  },
  sunmeter: {
    img: '/static/images/sun.svg',
    name: 'Solarimetro',
    notes: "Solarimetro dell'impianto",
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: [
        {
          type: 'circle',
          radius: 30,
          fill: '#fff',
          stroke: '#000',
          originX: 'center',
          originY: 'center'
        },
        {
          type: 'text',
          text: 'S',
          left: 0,
          top: 0,
          originX: 'center',
          originY: 'center'
        }
      ]
    },
    modal: (props) => <SolarimeterContent {...props} />,
    tooltip: (props) => <ContentTooltip {...props} />,
    // onReceive: (canvasObj, currentValues) => (objModified),
    onReceive: (canvasObj, config) => {
      // 1. se non esiste ritorno null
      // console.log('sunmeter - config: ', config)
      // console.log('sunmeter: ', canvasObj)

      const newObj = canvasObj
      let generalStatus = ''

      for (let s = 0; s < config.devices.length; s++) {
        if (
          config.devices[s].properties &&
          config.devices[s].properties.status &&
          config.devices[s].properties.status.value &&
          config.devices[s].properties.status.value.code
        ) {
          generalStatus = config.devices[s].properties.status.value.code
        }
      }

      if (newObj.objects && newObj.objects.length > 0) {
        if (generalStatus === 'ok') {
          // newObj.objects[0].fill = 'white'
          newObj.objects[0].fill = (config.data && config.data.draw && config.data.draw.backgroundColor) || 'white'
        } else if (generalStatus === 'warning' || generalStatus === 'maintenance') {
          newObj.objects[0].fill = 'yellow'
        } else if (generalStatus === 'error') {
          newObj.objects[0].fill = 'red'
        }
      }

      return newObj
    },
    event: {
      mouseDown: () => {
        console.log('onMouseDown')
      },
      mouseOver: (config) => {
        const infos = []
        console.log('onMouseOver: ', config)
        for (let s = 0; s < config.devices.length; s++) {
          for (const n in config.devices[s].properties) {
            if (n !== 'status') {
              const label = String(config.devices[s].properties[n].label)
              const value = String(config.devices[s].properties[n].value)
              infos.push({ label, value })
            }
          }
        }

        return infos
      }
    }
  },
  energymeter: {
    img: '/static/images/energy.svg',
    name: 'Meter',
    notes: "Meter dell' impianto",
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: [
        {
          type: 'circle',
          radius: 30,
          fill: '#fff',
          stroke: '#000',
          originX: 'center',
          originY: 'center'
        },
        {
          type: 'text',
          text: 'M',
          left: 0,
          top: 0,
          originX: 'center',
          originY: 'center'
        }
      ]
    },
    modal: (props) => <MeterContent {...props} />,
    tooltip: (props) => <ContentTooltip {...props} />,
    onReceive: (canvasObj, config) => {
      // 1. se non esiste ritorno null
      // console.log('meter - config: ', config)
      // console.log('meter - canvasObj: ', canvasObj)

      const newObj = canvasObj
      let generalStatus = ''

      for (let s = 0; s < config.devices.length; s++) {
        if (
          config.devices[s].properties &&
          config.devices[s].properties.status &&
          config.devices[s].properties.status.value &&
          config.devices[s].properties.status.value.code
        ) {
          generalStatus = config.devices[s].properties.status.value.code
        }
      }
      if (newObj.objects && newObj.objects.length > 0) {
        if (generalStatus === 'ok') {
          // newObj.objects[0].fill = 'white'
          newObj.objects[0].fill = (config.data && config.data.draw && config.data.draw.backgroundColor) || 'white'
        } else if (generalStatus === 'warning' || generalStatus === 'maintenance') {
          newObj.objects[0].fill = 'yellow'
        } else if (generalStatus === 'error') {
          newObj.objects[0].fill = 'red'
        }
      }

      return newObj
    },
    event: {
      mouseDown: () => {
        console.log('onMouseDown')
      },
      mouseOver: (config) => {
        const infos = []
        console.log('onMouseOver: ', config)
        for (let s = 0; s < config.devices.length; s++) {
          for (const n in config.devices[s].properties) {
            if (n !== 'status') {
              const label = String(config.devices[s].properties[n].label)
              const value = String(config.devices[s].properties[n].value)
              infos.push({ label, value })
            }
          }
        }

        return infos
      }
    }
  },
  text: {
    img: '/static/images/text.svg',
    name: 'Testo',
    notes: 'Oggetto di testo per scrivere liberamente',
    draw: {
      type: 'text',
      text: 'Testo',
      backgroundColor: '#fff',
      left: 0,
      top: 0
    },
    modal: (props) => <TextContent {...props} />,
    // onReceive: (canvasObj, currentValues) => (objModified),
    onReceive: (thisDraw, config) => {
      // console.log(thisDraw, config)
      if (config.data && config.data.currentText) {
        thisDraw.text = config.data.currentText
      }
      return thisDraw
    },
    event: {
      onLoad: (thisDraw, config) => {
        // console.log(thisDraw, config)
        if (config.data && config.data.currentText) {
          thisDraw.text = config.data.currentText
        }
        return thisDraw
      },
      mouseDown: () => {
        console.log('onMouseDown')
      },
      mouseOver: () => {
        console.log('onMouseOver')
      }
    }
  },
  infobox: {
    img: '/static/images/text.svg',
    name: 'Infobox',
    notes: "Oggetto di testo per rappresentare valori sugli altri elementi dell'impianto",
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: []
    },
    modal: (props) => <InfoboxContent {...props} />,
    // onReceive: (canvasObj, currentValues) => (objModified),
    onReceive: (thisDraw, config) => {
      const backgroundColor = config.data && config.data.draw && config.data.draw.backgroundColor
      thisDraw.objects = []
      // console.log('infobox - onReceive: ', thisDraw)
      // console.log('infobox - onReceive config: ', config)
      for (let s = 0; s < config.devices.length; s++) {
        let index = 2
        const selectedProperties = config.additionalData?.selectedProperties ?? []

        const filteredProperties = Object.keys(config.devices[s].properties)
          .filter(el => selectedProperties.includes(el))
          .reduce((acc, propKey) => {
            if (!acc[propKey]) {
              acc[propKey] = config.devices[s].properties[propKey]
            }

            return acc
          }, {})

        const background = {
          type: 'rect',
          width: 150,
          height: Object.keys(filteredProperties).filter(el => el !== 'status').length * 12 + 20,
          fill: backgroundColor || 'white',
          stroke: backgroundColor || 'white',
          top: -5,
          left: 10,
          originX: 'left',
          originY: 'top'
        }
        thisDraw.objects.push(background)
        const name = {
          type: 'text',
          text: String(config.data?.draw?.name),
          left: 10,
          top: 0,
          originX: 'left',
          originY: 'center',
          fontSize: 10,
          textAlign: 'left'
        }
        if (config.data?.draw?.name) {
          thisDraw.objects.push(name)
        }
        for (const n in filteredProperties) {
          if (n !== 'status') {
            const label = {
              type: 'text',
              text: String(filteredProperties[n].label),
              left: 10,
              top: index === 2 ? 14 : (index - 1) * 12,
              originX: 'left',
              originY: 'center',
              fontSize: 8,
              textAlign: 'left'
            }
            thisDraw.objects.push(label)
            const value = {
              type: 'text',
              text: String(filteredProperties[n].value),
              left: 120,
              top: index === 2 ? 14 : (index - 1) * 12,
              originX: 'right',
              originY: 'center',
              fontSize: 8,
              textAlign: 'right'
            }
            thisDraw.objects.push(value)
            index++
          }
        }
      }
      return thisDraw
    },
    event: {
      onLoad: (thisDraw, config) => {
        thisDraw.objects = []
        // console.log('onLoad: ', thisDraw)
        // console.log('config: ', config)
        for (let s = 0; s < config.devices.length; s++) {
          let index = 2
          const selectedProperties = config.additionalData?.selectedProperties ?? []

          const filteredProperties = Object.keys(config.devices[s].properties)
            .filter(el => selectedProperties.includes(el))
            .reduce((acc, propKey) => {
              if (!acc[propKey]) {
                acc[propKey] = config.devices[s].properties[propKey]
              }

              return acc
            }, {})

          const background = {
            type: 'rect',
            width: 150,
            height: Object.keys(filteredProperties).filter(el => el !== 'status').length * 10 + 14,
            fill: 'white',
            stroke: 'white',
            top: -5,
            left: 10,
            originX: 'left',
            originY: 'top'
          }
          thisDraw.objects.push(background)
          const name = {
            type: 'text',
            text: String(config.data?.draw?.name),
            left: 10,
            top: 0,
            originX: 'left',
            originY: 'center',
            fontSize: 10,
            textAlign: 'left'
          }
          if (config.data?.draw?.name) {
            thisDraw.objects.push(name)
          }
          for (const n in filteredProperties) {
            if (n !== 'status') {
              const label = {
                type: 'text',
                text: String(filteredProperties[n]?.label),
                left: 10,
                top: index === 2 ? 14 : (index - 1) * 12,
                originX: 'left',
                originY: 'center',
                fontSize: 8,
                textAlign: 'left'
              }
              thisDraw.objects.push(label)
              const value = {
                type: 'text',
                text: String(filteredProperties[n]?.value),
                left: 100,
                top: index === 2 ? 14 : (index - 1) * 12,
                originX: 'right',
                originY: 'center',
                fontSize: 8,
                textAlign: 'right'
              }
              thisDraw.objects.push(value)
              index++
            }
          }
        }

        return thisDraw
      },
      mouseDown: () => {
        console.log('onMouseDown')
      },
      mouseOver: () => {
        console.log('onMouseOver')
      }
    }
  },
  obstacle: {
    img: '/static/images/warning.svg',
    name: 'Impedimento',
    notes: 'Segnalazione di un impedimento',
    draw: {
      type: 'triangle',
      width: 50,
      height: 50,
      left: 0,
      top: 0,
      fill: '#fbc02d',
      stroke: '#fbc02d'
    },
    modal: (props) => <ObstacleContent {...props} />,
    onReceive: () => { },
    event: {
      mouseDown: () => {
        console.log('onMouseDown')
      },
      mouseOver: () => {
        console.log('onMouseOver')
      }
    }
  },
  windrose: {
    img: '/static/images/windrose.svg',
    name: 'Rosa dei venti',
    notes: 'Rosa dei venti',
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      width: 50,
      height: 50,
      objects: [
        {
          type: 'path',
          path: 'M25.5275 4.42575L22.3845 19.7038L16.7112 15.9451L16.5917 16.0654L20.3072 21.7857L5.20853 24.9525V25.2257L20.3015 28.3925L16.5618 34.1494L16.6809 34.2698L22.3578 30.5095L25.4998 45.7864H25.7705L28.9142 30.5059L34.5879 34.2644L34.707 34.1441L30.99 28.4217L46.0876 25.2544V24.9812L30.9981 21.8151L34.7348 16.0628L34.6154 15.9425L28.9398 19.702L25.7979 4.42621H25.5272L25.5275 4.42575ZM25.525 5.80984V21.7845V24.7477L22.2991 21.489L22.6327 19.8683L25.525 5.80995V5.80984ZM34.0107 16.5506L29.2755 21.3326L28.9775 19.8846L34.0106 16.5506H34.0107ZM17.194 16.6759L21.9185 21.447L20.4878 21.7471L17.194 16.676V16.6759ZM29.1919 21.7203L30.8346 22.0651L44.7173 24.978H28.9428H25.966L29.1925 21.7203H29.1919ZM6.57903 25.2281H22.3571H25.3335L22.107 28.4865L20.464 28.1416L6.57891 25.2281H6.57903ZM25.7722 25.4617L28.9987 28.7201L28.6652 30.3408L25.7722 44.4015V28.4233V25.4611V25.4617ZM30.8083 28.4588L34.1036 33.5326L29.3766 28.7589L30.8083 28.4588ZM22.0218 28.8782L22.3195 30.3265L17.2855 33.6613L22.0218 28.8782Z',
          fill: '#000'
        },
        {
          type: 'path',
          path: 'M26.4982 0.426919H26.0186V0H27.3973V0.426919H26.9042V3.4585H26.3822L24.7693 0.533649V2.99525H25.2512V3.42216H23.8748V2.99525H24.3544V0.426919H23.8748V0H25.0214L26.4982 2.65916V0.426919Z',
          fill: '#000'
        },
        {
          type: 'path',
          path: 'M26.3244 46.707V46.4783H26.7418V47.4934H26.3244V47.2624C26.1475 47.0017 25.9055 46.8713 25.5985 46.8713C25.4111 46.8713 25.2674 46.9084 25.1676 46.9824C25.0678 47.0565 25.0179 47.1631 25.0179 47.3023C25.0179 47.4356 25.0648 47.5371 25.1585 47.6066C25.2538 47.6762 25.3989 47.7355 25.594 47.7843L25.9955 47.8887C26.2511 47.9553 26.4469 48.0435 26.583 48.1531C26.7205 48.2626 26.8098 48.3789 26.8506 48.5018C26.893 48.6247 26.9141 48.7617 26.9141 48.9127C26.9141 49.2163 26.8173 49.4569 26.6238 49.6347C26.4302 49.8124 26.1709 49.9012 25.8458 49.9012C25.5358 49.9012 25.2538 49.7901 24.9997 49.568V49.8456H24.5733V48.6262H24.9997V48.9216C25.2039 49.2888 25.4701 49.4725 25.7981 49.4725C25.9902 49.4725 26.1391 49.4243 26.245 49.3281C26.3509 49.2303 26.4038 49.0934 26.4038 48.9172C26.4038 48.6773 26.2314 48.5107 25.8866 48.4174L25.4602 48.2997C25.1532 48.2137 24.9264 48.0975 24.7797 47.9509C24.6331 47.8043 24.5597 47.6111 24.5597 47.3711C24.5597 47.0765 24.6481 46.8484 24.825 46.6871C25.0035 46.5241 25.2492 46.4427 25.5622 46.4427C25.8344 46.4427 26.0885 46.5308 26.3244 46.707Z',
          fill: '#000'
        },
        {
          type: 'path',
          path: 'M3.80429 25.04C3.80429 25.3611 3.73389 25.6466 3.59309 25.8965C3.45359 26.1464 3.26029 26.3398 3.01309 26.4767C2.76599 26.6122 2.48489 26.6798 2.17019 26.6798C1.85949 26.6798 1.58329 26.6093 1.34169 26.4683C1.09999 26.3259 0.911488 26.1297 0.776188 25.8798C0.642288 25.6285 0.575287 25.3458 0.575287 25.0316C0.575287 24.7203 0.643688 24.4424 0.780388 24.198C0.918488 23.9538 1.10829 23.7631 1.34999 23.6263C1.59159 23.488 1.86629 23.419 2.17429 23.419C2.49459 23.419 2.77839 23.4902 3.02549 23.6326C3.27269 23.7736 3.46389 23.967 3.59919 24.2127C3.73589 24.4585 3.80429 24.7342 3.80429 25.04ZM2.18879 26.2589C2.40279 26.2589 2.59399 26.2024 2.76249 26.0892C2.93089 25.9762 3.06079 25.8261 3.15189 25.6389C3.24439 25.4519 3.29059 25.2522 3.29059 25.04C3.29059 24.8236 3.24169 24.6232 3.14359 24.4389C3.04699 24.2533 2.91299 24.1073 2.74179 24.0012C2.57049 23.8937 2.37729 23.8399 2.16179 23.8399C1.95059 23.8399 1.76349 23.8923 1.60059 23.997C1.43769 24.1003 1.31129 24.2435 1.22149 24.4264C1.13319 24.6093 1.08899 24.8124 1.08899 25.0358C1.08899 25.2731 1.13319 25.4847 1.22149 25.6704C1.31129 25.856 1.43969 26.0006 1.60679 26.1039C1.77389 26.2072 1.96789 26.2589 2.18879 26.2589Z',
          fill: '#000'
        },
        {
          type: 'path',
          path: 'M49.1097 24.2311V23.6528H47.6439V24.6832H48.9188V25.1147H47.6439V26.2484H49.1097V25.6173H49.5324V26.6798H46.6849V26.2484H47.1258V23.6528H46.6849V23.2213H49.5324V24.2311H49.1097Z',
          fill: '#000'
        }
      ]
    },
    modal: (props) => <WindRoseContent {...props} />,
    onReceive: () => { },
    event: {
      mouseDown: () => { },
      mouseOver: () => { }
    }
  }
}
