import { Popover } from '@material-ui/core'
import { useState } from 'react'
import { HexColorPicker } from 'react-colorful'

const SwatchColorPicker = ({ style = undefined, onChange = undefined, color = undefined }) => {
  const currentColor = color ?? '#ffffff'

  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedColor, setSelectedColor] = useState(currentColor)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleColorSelection = (e) => {
    e.persist()
    if (onChange) {
      onChange(selectedColor)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'color-popover' : undefined

  return (
    <div>
      <div aria-describedby={id} style={style ?? { borderRadius: '50%', width: 30, height: 30, backgroundColor: selectedColor }} onClick={handleClick} />
      <Popover
        PaperProps={{ style: { overflowY: 'hidden' } }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <HexColorPicker
          color={selectedColor}
          onChange={setSelectedColor}
          onMouseUp={handleColorSelection}
        />
      </Popover>
    </div>
  )
}

export default SwatchColorPicker
