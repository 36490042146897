import { Box, Button, Card, Chip, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { baseValues, formatPeriod, getDashboardTypes } from './SidebarFilters/FiltersContent/utils'
import useAuth from '@/hooks/useAuth'
import { useSnackbar } from 'notistack'
import { useSelector } from '@/store'
import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useClientRect } from '@/hooks/useClientRect'
import clsx from 'clsx'
import api from '@micmnt/apis'
import PeriodModal from './PeriodModal'
import { Filter as FilterIcon, ChevronLeft, ChevronRight } from 'react-feather'
import SidebarFilters from './SidebarFilters'
import TrendOverTimeGraph from '@/components/charts/TrendOverTimeGraph'
import LoadingCard from '@/components/LoadingCard'
import { decodeTrendGraph, getAggregationTypeFromPeriod } from '../utils'
import { v4 as uuidv4 } from 'uuid'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  loading: {
    width: '100%'
  },
  section: {
    padding: theme.spacing(1, 2),
    width: '100%',
    height: '100%'
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  topMargin: {
    marginTop: (theme.spacing(2) - 4)
  },
  smallViewport: {
    overflowY: 'auto',
    height: '100%'
  },
  filterTitle: {
    color: theme.palette.common.white
  },
  chip: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1, 2, 1, 0)
  },
  glassBackground: {
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'saturate(120%) blur(16px)',
      backdropFilter: 'saturate(190%) blur(16px)',
      backgroundColor: 'rgba(16, 26, 38, 0.05)'
    }
  }
}))

const Analytics = ({ hasGlass = false, className, ...rest }) => {
  const classes = useStyles()
  const { baseDate, baseDashboardType, getBaseFilter } = baseValues
  const { user, updateAnalyticsFilters: ctxUpdateUserAnalyticsFilters } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const { devices, uuid: plantId } = useSelector(state => state.hydroPlantView)
  const [filterOpen, setFilterOpen] = useState(false)
  const [dashboardType, setDashboardType] = useState(baseDashboardType)
  const [currentPeriod, setCurrentPeriod] = useState(baseDate)
  const [startDate, setStartDate] = useState(moment().subtract(1, 'days').startOf('day'))
  const [endDate, setEndDate] = useState(moment().endOf('day'))
  const [currentFilter, setCurrentFilter] = useState(getBaseFilter())
  const [isLoading, setIsLoading] = useState(false)
  const [openPeriodModal, setOpenPeriodModal] = useState(false)
  const [refreshPage, setRefreshPage] = useState(null)

  const [rect, ref] = useClientRect()
  const [trend, setTrend] = useState([])

  const graphHeight = rect ? rect.height - 220 : '100%'

  const availableFilters = user.metadata?.analyticsFilters?.filter(el => el.plantId === plantId) ?? []

  const getData = useCallback(async (type, timeFrom, timeTo, filter) => {
    const config = {}
    // Preparo l'oggetto config ad ospitare il numero corretto di grafici
    filter[type].forEach((el, index) => {
      if (el.elements?.length > 0) {
        if (type === 'trend' && el.elements.find(prop => prop.uuid === 'plant')) {
          config[`graph${index + 1}`] = {
            aggregationType: el.aggregationType || getAggregationTypeFromPeriod(timeFrom, timeTo),
            elements: [{ resourceType: 'plant', resourceId: plantId }]
          }
        } else if (type === 'trend') {
          const properties = el.elements

          const elements = [...new Set(properties.map(prop => prop.deviceId))]
            .map(id => ({
              properties: properties.filter(el => el.deviceId === id && el.selected === true).map(el => el.name),
              resourceType: properties.find(el => el.deviceId === id)?.deviceType ?? 'device',
              resourceId: id
            }))

          const aggregationType = !el.aggregationType || el.aggregationType === 'auto' ? getAggregationTypeFromPeriod(timeFrom, timeTo) : el.aggregationType

          config[`graph${index + 1}`] = {
            aggregationType: aggregationType,
            elements
          }
        }
      }
    })
    // Oggetto che contiene i parametri per effettuare la chiamata API
    const paramsObj = {
      type,
      timeFrom,
      timeTo,
      plantId,
      config
    }

    const params = JSON.stringify(paramsObj)

    if (plantId) {
      const { data: response, error } = await api.get({
        savedUrl: 'plantDetails',
        path: `/${plantId}/analytics?q=${params}`
      })

      if (error) {
        return null
      }
      return response
    }
  }, [plantId, currentFilter])

  useEffect(() => {
    async function initialiseView () {
      if (dashboardType === 'trend') {
        setIsLoading(true)
        const plantFilter = availableFilters?.[0] ?? {}
        const trendFilters = plantFilter?.trend?.filter(el => el.show === true) ?? []
        const trendFilter = trendFilters?.[0]
        const formattedFilter = trendFilter ? { trend: [trendFilter] } : getBaseFilter()
        const data = await getData(dashboardType, startDate, endDate, formattedFilter)
        if (data) {
          const newTrend = Object.keys(data).map((key, index) => decodeTrendGraph({ ...data[key] }, formattedFilter.trend[index]))
          setTrend(newTrend)
        }
        setCurrentFilter(formattedFilter)
        setIsLoading(false)
        setRefreshPage(false)
      }
    }

    if (refreshPage === false) {
      return
    }

    if ((dashboardType && startDate && endDate)) {
      initialiseView()
    }
  }, [dashboardType, startDate, endDate, refreshPage])

  // Funzione che crea e salva un filtro utente
  const saveFilters = async (filter, name, type, startDate, endDate) => {
    // 1. Creare un uuid per il filtro
    const filterId = uuidv4()
    const newFilter = {
      ...filter,
      name,
      type,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      plantId,
      uuid: filterId
    }
    // Filtri esistenti dell'utente, se non ce ne sono partiamo da un array vuoto
    const userFilters = user.metadata?.analyticsFilters ?? []
    const newUserFilters = [...userFilters, newFilter]
    // 2. creare un nuovo oggetto metadata con il filtro in questione
    const newMetadata = {
      ...user.metadata,
      analyticsFilters: newUserFilters
    }
    // 3. creare un nuovo oggetto utente aggiornato
    const newUser = {
      ...user,
      metadata: newMetadata
    }
    // 4. eseguire la chiamata api che aggiorna l'utente
    const { data: responseUser, error } = await api.put({ savedUrl: 'accounts', path: '/self', body: newUser })
    if (error) {
      return enqueueSnackbar('Errore durante la creazione del filtro', { variant: 'error' })
    }
    const newFilters = responseUser?.metadata?.analyticsFilters ?? null
    // 5. aggiornare il context se la chiamata va a buon fine
    if (newFilters) {
      ctxUpdateUserAnalyticsFilters(newFilters)
    }
    // 6. mostrare una notifica di successo/errore in base all'esito della chiamata
    enqueueSnackbar('Filtro creato con successo!', { variant: 'success' })
  }

  // Funzione che modifica e salva un filtro utente
  const editFilters = async (filter, name, type, startDate, endDate) => {
    const newFilter = {
      ...filter
    }

    newFilter.startDate = moment(startDate).toISOString()
    newFilter.endDate = moment(endDate).toISOString()
    if (name) {
      newFilter.name = name
    }

    // Filtri esistenti dell'utente, se non ce ne sono partiamo da un array vuoto
    const userFilters = user.metadata?.analyticsFilters ?? []
    const currentFilterIndex = userFilters.findIndex(el => el.uuid === newFilter.uuid)
    if (currentFilterIndex > -1) {
      const newUserFilters = [...userFilters]
      newUserFilters.splice(currentFilterIndex, 1, newFilter)
      // 2. creare un nuovo oggetto metadata con il filtro in questione
      const newMetadata = {
        ...user.metadata,
        analyticsFilters: newUserFilters
      }
      // 3. creare un nuovo oggetto utente aggiornato
      const newUser = {
        ...user,
        metadata: newMetadata
      }

      const { data: responseUser, error } = await api.put({ savedUrl: 'accounts', path: '/self', body: newUser })
      if (error) {
        return enqueueSnackbar('Errore durante la modifica del filtro', { variant: 'error' })
      }
      const newFilters = responseUser?.metadata?.analyticsFilters ?? null
      // 5. aggiornare il context se la chiamata va a buon fine
      if (newFilters) {
        ctxUpdateUserAnalyticsFilters(newFilters)
      }
      // 6. mostrare una notifica di successo/errore in base all'esito della chiamata
      enqueueSnackbar('Filtro modificato con successo!', { variant: 'success' })
    }
  }

  // Funzione che elimina un filtro utente
  const deleteFilter = async (filterId) => {
    const userFilters = user.metadata?.analyticsFilters ?? []
    const newUserFilters = userFilters.filter(el => el.uuid !== filterId)
    const newMetadata = {
      ...user.metadata,
      analyticsFilters: newUserFilters
    }
    const newUser = {
      ...user,
      metadata: newMetadata
    }
    const { data: responseUser, error } = await api.put({ savedUrl: 'accounts', path: '/self', body: newUser })
    if (error) {
      return enqueueSnackbar('Errore durante l\'eliminazione del filtro', { variant: 'error' })
    }
    const newFilters = responseUser?.metadata?.analyticsFilters ?? null
    // 5. aggiornare il context se la chiamata va a buon fine
    if (newFilters) {
      ctxUpdateUserAnalyticsFilters(newFilters)
    }
    // 6. mostrare una notifica di successo/errore in base all'esito della chiamata
    enqueueSnackbar('Filtro eliminato con successo!', { variant: 'success' })
  }

  // Funzione che prende in ingresso il filtro corrente e crea la stringa contenente gli elementi presi in considerazione dal filtro
  const getFilterElements = (filter) => {
    const currentFilterView = filter[dashboardType]
    if (currentFilterView) {
      // Ricreo un array contenente tutti gli elementi di ogni grafico dei filtri
      const currentElements = currentFilterView.map(currFilter => currFilter.elements).flat()
      const uniqueElements = [...new Set(currentElements.filter(el => (el && el !== undefined)).map(el => el.uuid))].map(uuid => currentElements.find(el => el.uuid === uuid))
      return dashboardType === 'trend' ? currentElements.map(el => el.displayName || '').join(', ') : uniqueElements.map(el => (el.name || '')).join(', ')
    }
  }

  // funzione che in base al tipo di dashboard da visualizzare restituisce i grafici corretti
  const getCurrentDashboard = (viewType) => {
    switch (viewType) {
      case 'trend': {
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card elevation={hasGlass ? 0 : 1} className={hasGlass ? clsx(classes.section, classes.glassBackground) : classes.section}>
                  <Grid container spacing={2}>
                    {!isLoading && trend.length > 0
                      ? trend.map((el, elIndex) => currentFilter[dashboardType][elIndex].show === true
                          ? (
                            <Grid item xs={12} key={`trend-${elIndex}`}>
                              <Typography className={classes.sectionTitle} variant='h6'>{`Andamento nel tempo ${elIndex + 1}`}</Typography>
                              <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                                <TrendOverTimeGraph rawLabels name={`trend-${elIndex}`} height={graphHeight < 300 ? 300 : trend.length > 1 ? graphHeight * 0.5 : graphHeight} data={el} />
                              </Box>
                            </Grid>)
                          : (
                            <Grid item xs={12}>
                              <Typography className={classes.sectionTitle} variant='h6'>{`Andamento nel tempo ${elIndex + 1}`}</Typography>
                              <Typography variant='body1'>Grafico non attivo</Typography>
                            </Grid>))
                      : (
                        <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                          <LoadingCard glass={hasGlass} className={classes.loading} size={40} />
                        </Box>)}
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )
      }
      default: {
        return null
      }
    }
  }

  // funzione che in base al parametro ricevuto, porta data iniziale e data finale di un periodo indietro o avanti
  const nextOrPrevPeriod = (type, start, end) => {
    const newStartDate = type === 'prev' ? moment(start).subtract(1, 'days') : moment(start).add(1, 'days')
    const newEndDate = type === 'prev' ? moment(end).subtract(1, 'days') : moment(end).add(1, 'days')
    const newPeriod = `${newStartDate.format('DD/MM')} - ${newEndDate.format('DD/MM')}`
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    setRefreshPage(true)
    setCurrentPeriod(newPeriod)
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {openPeriodModal
        ? (
          <PeriodModal
            confirm={(start, end) => {
              const newPeriod = `${moment(start).format('DD/MM')} - ${moment(end).format('DD/MM')}`
              setStartDate(start)
              setEndDate(end)
              setRefreshPage(true)
              setCurrentPeriod(newPeriod)
              setOpenPeriodModal(false)
            }}
            start={startDate}
            end={endDate}
            open={openPeriodModal}
            onClose={() => setOpenPeriodModal(false)}
          />
          )
        : null}
      <Box mt={2}>
        <Box width='100%' display='flex' alignItems='center'>
          <Typography className={classes.filterTitle} variant='h6'>Filtri attivi</Typography>
        </Box>
        <Grid width='100%' container spacing={2}>
          <Grid item xs={10} md={10} xl={8}>
            <Box width='100%' display='flex' alignItems='center'>
              {
                (getDashboardTypes().find(el => el.value === dashboardType)
                  ? (
                    <Chip
                      size='small'
                      className={classes.chip}
                      label={`Tipo: ${getDashboardTypes().find(el => el.value === dashboardType).label}`}
                    />)
                  : null)

              }
              {currentPeriod
                ? (
                  <Chip
                    size='small'
                    className={classes.chip}
                    label={currentPeriod}
                    color='secondary'
                    onClick={() => setOpenPeriodModal(true)}
                    deleteIcon={moment().diff(endDate, 'days') > 0 ? <ChevronRight style={{ cursor: 'pointer' }} size={18} color='#e0e0e0' /> : null}
                    {...{
                      onDelete: moment().diff(endDate, 'days') > 0 ? () => nextOrPrevPeriod('next', startDate, endDate) : null
                    }}
                    icon={
                      <ChevronLeft
                        size={18}
                        onClick={(e) => {
                          // serve stoppare la propagazione dell'evento per impedire
                          // anche l'apertura del calendario invece che la chiamata alla funzione di cambio periodo
                          e.stopPropagation()
                          nextOrPrevPeriod('prev', startDate, endDate)
                        }}
                      />
                    }
                  />)
                : null}
              {currentFilter && Object.keys(currentFilter).length > 0
                ? (
                  <Tooltip title={getFilterElements(currentFilter)}>
                    <Chip style={{ maxWidth: 300 }} size='small' className={classes.chip} label={getFilterElements(currentFilter)} />
                  </Tooltip>)
                : null}
            </Box>
          </Grid>
          <Grid item xs={2} xl={4}>
            <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
              <Button onClick={() => setFilterOpen(true)} size='small' variant='contained' color='primary' startIcon={<FilterIcon size={14} />}>Modifica</Button>
            </Box>
            <SidebarFilters
              applyFilters={(filter, viewType, startPeriod, endPeriod) => {
                setCurrentFilter(filter)
                setDashboardType(viewType)
                setStartDate(startPeriod)
                setEndDate(endPeriod)
                setCurrentPeriod(formatPeriod(moment(startPeriod), moment(endPeriod)))
                setRefreshPage(true)
              }}
              plantId={plantId}
              editFilters={editFilters}
              saveFilters={saveFilters}
              deleteFilter={deleteFilter}
              devices={devices}
              open={filterOpen}
              onClose={() => setFilterOpen(false)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box ref={ref} width='100%' height='100%'>
        {getCurrentDashboard(dashboardType)}
      </Box>
    </div>
  )
}

export default Analytics
